<template>
  <div class="inspection-plan-detail-wrapper">
    <div class="inspection-plan-detail-header flex-sbc">
      <div class="left" @click="$router.push({ name: 'warehouseRecord' })">
        <img src="@/assets/information/procedure/左滑@2x.png" style="width: 8px">
        <span style="padding-left: 10px;cursor: pointer">{{ typeList[params.operateType] + '详情' }}</span>
      </div>
    </div>
    <div class="inspection-plan-detail-body">
      <div class="detail-card">
        <div class="card-tit">基本信息</div>
        <div class="card-bot">
          <el-row class="view-list" :gutter="24">
            <!--            暂时这么处理距离问题-->
            <template v-for="(item, index) in baseColumns[coulmnTypeBase[params.operateType]]">
              <el-col
                v-if="item.label"
                :key="item.prop"
                :span="6"
                :style="{marginBottom: index !== 4 ? '20px' : 0 }"
              >
                <span style="color: #9597AE;">{{ item.label }}：</span>
                <span class="bot-tis">{{ getValue(item) }}</span>
              </el-col>
            </template>
          </el-row>
        </div>
      </div>
      <div class="detail-card">
        <div class="card-tit">{{ [0, 1].includes(+params.operateType) ?'散件' : '物料' }}</div>
        <div class="card-bot">
          <MTable
            ref="pointCheckTable"
            :height="300"
            :columns="columns[coulmnType[params.operateType]]"
            :columns-setting="false"
            :show-page="false"
            :data="selectCheckCheckItem"
            :only-key="'uuid'"
          >
            <div slot="index" slot-scope="{ row, $index }">{{ $index + 1 }}</div>
            <div slot="warehouseBatchNumber" slot-scope="{ row }">{{ row.warehouseBatchNumber || '-' }}</div>
            <div slot="batchNumber" slot-scope="{ row }">{{ row.batchNumber || '-' }}</div>
            <div slot="sequenceNumber" slot-scope="{ row }">{{ row.sequenceNumber || '-' }}</div>
            <div slot="warehouseLocationName" slot-scope="{ row }">{{ row.warehouseLocationName || '-' }}</div>
          </MTable>
        </div>
      </div>
      <!--<div v-if="[0, 1].includes(+params.operateType)" class="detail-card">-->
        <!--<div class="card-tit">整件</div>-->
        <!--<div class="card-bot">-->
          <!--<MTable-->
            <!--ref="pointCheckTable"-->
            <!--:height="300"-->
            <!--:columns="[0].includes(+params.operateType) ? AllInMaterialColumn : AllOutMaterialColumn"-->
            <!--:columns-setting="false"-->
            <!--:show-page="false"-->
            <!--:data="selectCheckCheckItemAll"-->
            <!--:only-key="'uuid'"-->
          <!--&gt;-->
            <!--<div slot="index" slot-scope="{ row, $index }">{{ $index + 1 }}</div>-->
            <!--<div slot="batchNumber" slot-scope="{ row }">{{ row.batchNumber || '-' }}</div>-->
            <!--<div slot="sequenceNumber" slot-scope="{ row }">{{ row.sequenceNumber || '-' }}</div>-->
            <!--<div slot="warehouseLocationName" slot-scope="{ row }">{{ row.warehouseLocationName || '-' }}</div>-->
          <!--</MTable>-->
        <!--</div>-->
      <!--</div>-->
    </div>
  </div>
</template>

<script>
import conventionMixin from '@/mixin/conventional-page'
import api from '@/api/xiewei/spare-part/warehouse'
import FunctionHeader from '@/components/FunctionHeader/index'
import { getUUid, setTreeData } from '@/utils'
import { AllInMaterialColumn, AllOutMaterialColumn, InColumns, OutColumns, AllocationColumn,
  TransferWarehouseColumn, WarehouseInventoryColumn, MaterialInventoryColumn,
  InMaterialColumn, OutMaterialColumn, AllocationMaterialColumn,
  TransferMaterialColumn, WarehouseMaterialColumn, MaterialColumn } from './columns'
import { Encrypt } from '@/utils/sercet'
import dayjs from 'dayjs'

export default {
  name: 'WarehouseOperateDetail',
  components: { FunctionHeader },
  mixins: [conventionMixin],
  data() {
    return {
      api,
      AllInMaterialColumn,
      AllOutMaterialColumn,
      InMaterialColumn,
      OutMaterialColumn,
      AllocationMaterialColumn,
      TransferMaterialColumn,
      WarehouseMaterialColumn,
      MaterialColumn,
      baseColumns: {
        InColumns: InColumns,
        OutColumns: OutColumns,
        AllocationColumn: AllocationColumn,
        TransferWarehouseColumn: TransferWarehouseColumn,
        WarehouseInventoryColumn: WarehouseInventoryColumn,
        MaterialInventoryColumn: MaterialInventoryColumn
      },
      columns: {
        InMaterialColumn: [],
        OutMaterialColumn: [],
        AllocationMaterialColumn: [],
        TransferMaterialColumn: [],
        WarehouseMaterialColumn: [],
        MaterialColumn: []
      },
      fullScreen: false,
      params: {},
      baseFormData: {},
      buttonLoading: false,
      messages: {
        add: '新增成功',
        modify: '修改成功',
        copy: '复制成功',
        viewO: '查看',
        addO: '新增',
        modifyO: '修改'
      },
      coulmnType: ['InMaterialColumn', 'OutMaterialColumn', 'AllocationMaterialColumn', 'TransferMaterialColumn', 'WarehouseMaterialColumn', 'MaterialColumn'],
      coulmnTypeBase: ['InColumns', 'OutColumns', 'AllocationColumn', 'TransferWarehouseColumn', 'WarehouseInventoryColumn', 'MaterialInventoryColumn'],
      typeList: ['入库', '出库', '调拨', '移库', '仓库盘点', '物料盘点'],
      selectCheckCheckItem: [],
      selectCheckCheckItemAll: []
    }
  },
  mounted() {
    this.params = this.$route.query
    if (+this.params.isOperate !== 1) {
      this.getColumn()
    }
    this.getWarehouseOperationSheetDetail(this.params.number)
  },
  methods: {
    getColumn() {
      if (+this.params.operateType === 0) {
        this.columns.InMaterialColumn = this.InMaterialColumn
      }
      if (+this.params.operateType === 1) {
        this.columns.OutMaterialColumn = this.OutMaterialColumn
      }
      if (+this.params.operateType === 2) {
        this.columns.AllocationMaterialColumn = this.AllocationMaterialColumn
      }
      if (+this.params.operateType === 3) {
        this.columns.TransferMaterialColumn = this.TransferMaterialColumn
      }
      if (+this.params.operateType === 4) {
        this.columns.WarehouseMaterialColumn = this.WarehouseMaterialColumn
      }
      if (+this.params.operateType === 5) {
        this.columns.MaterialColumn = this.MaterialColumn
      }
    },
    async getWarehouseOperationSheetDetail(number) {
      const res = await api.getWarehouseOperationSheetDetailByNumber({ number })
      if (res) {
        this.baseFormData = res
        if (+this.params.isOperate === 1) {
          this.params.operateType = res.type
          this.getColumn()
        }
        this.baseFormData.warehouseLocationIds = res.selectedWarehouseLocationIds ? res.selectedWarehouseLocationList.map(item => item.name).join('，') : '全部'
        const datas = res.warehouseOperationDetailsList.filter(item => +item.isContainer !== 1)
        const allData = res.warehouseOperationDetailsList.filter(item => +item.isContainer === 1)
        if (+this.params.operateType === 0) {
          this.selectCheckCheckItem = datas.map(item => {
            return {
              ...item,
              id: item.materialsId,
              name: item.materialsName,
              code: item.materialsCode,
              specifications: item.materialsSpecifications,
              mainUnitName: item.materialsUnitName,
              uuid: getUUid()
            }
          })
          this.selectCheckCheckItemAll = allData.map(item => {
            return {
              ...item,
              id: item.materialsId,
              name: item.materialsName,
              code: item.materialsCode,
              specifications: item.materialsSpecifications,
              mainUnitName: item.materialsUnitName,
              uuid: getUUid()
            }
          })
        } else {
          this.selectCheckCheckItem = datas.map(item => {
            return {
              ...item,
              uuid: getUUid()
            }
          })
          if (+this.params.operateType === 1) {
            this.selectCheckCheckItemAll = allData.map(item => {
              return {
                ...item,
                uuid: getUUid()
              }
            })
          }
        }
      }
    },
    getValue(row) {
      const result = this.baseFormData[row.prop]
      if (row.form && row.form.options) {
        const current = row.form.options.find(item => item.id === result)
        return current && current.name
      }
      return result
    }
  }
}
</script>

<style scoped lang="scss">
.inspection-plan-detail-wrapper {
  height: 100%;

  .inspection-plan-detail-header {
    width: 100%;
    padding: 10px 0;
  }

  .inspection-plan-detail-body {
    height: calc(100% - 60px);
    overflow-y: auto;

    .detail-card {
      margin-bottom: 20px;
    }
  }
  ::v-deep {
    .el-col.el-col-6 >.el-form-item {
      min-height: 91px;
    }
    .el-col.el-col-12 >.el-form-item {
      min-height: 91px;
    }
    .date-number {
      width: 100%;
      display: flex;

      .el-form-item:first-child {
        width: 138% !important;

        input {
          border-radius: 4px 0 0 4px;
        }
      }

      .el-form-item:last-child {
        width: 62% !important;

        input {
          border-radius: 0 4px 4px 0;
          border-left: 0;
        }
      }
    }
  }
  .function-header-wrap {
    padding-top: 0;
  }
  ::v-deep {
    .table-row-img {
      >img {
        width: 50px;
      }
    }
  }
}
</style>
