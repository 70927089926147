var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "inspection-plan-detail-wrapper" }, [
    _c("div", { staticClass: "inspection-plan-detail-header flex-sbc" }, [
      _c(
        "div",
        {
          staticClass: "left",
          on: {
            click: function($event) {
              return _vm.$router.push({ name: "warehouseRecord" })
            }
          }
        },
        [
          _c("img", {
            staticStyle: { width: "8px" },
            attrs: {
              src: require("@/assets/information/procedure/左滑@2x.png")
            }
          }),
          _c(
            "span",
            { staticStyle: { "padding-left": "10px", cursor: "pointer" } },
            [_vm._v(_vm._s(_vm.typeList[_vm.params.operateType] + "详情"))]
          )
        ]
      )
    ]),
    _c("div", { staticClass: "inspection-plan-detail-body" }, [
      _c("div", { staticClass: "detail-card" }, [
        _c("div", { staticClass: "card-tit" }, [_vm._v("基本信息")]),
        _c(
          "div",
          { staticClass: "card-bot" },
          [
            _c(
              "el-row",
              { staticClass: "view-list", attrs: { gutter: 24 } },
              [
                _vm._l(
                  _vm.baseColumns[_vm.coulmnTypeBase[_vm.params.operateType]],
                  function(item, index) {
                    return [
                      item.label
                        ? _c(
                            "el-col",
                            {
                              key: item.prop,
                              style: { marginBottom: index !== 4 ? "20px" : 0 },
                              attrs: { span: 6 }
                            },
                            [
                              _c(
                                "span",
                                { staticStyle: { color: "#9597AE" } },
                                [_vm._v(_vm._s(item.label) + "：")]
                              ),
                              _c("span", { staticClass: "bot-tis" }, [
                                _vm._v(_vm._s(_vm.getValue(item)))
                              ])
                            ]
                          )
                        : _vm._e()
                    ]
                  }
                )
              ],
              2
            )
          ],
          1
        )
      ]),
      _c("div", { staticClass: "detail-card" }, [
        _c("div", { staticClass: "card-tit" }, [
          _vm._v(
            _vm._s([0, 1].includes(+_vm.params.operateType) ? "散件" : "物料")
          )
        ]),
        _c(
          "div",
          { staticClass: "card-bot" },
          [
            _c("MTable", {
              ref: "pointCheckTable",
              attrs: {
                height: 300,
                columns: _vm.columns[_vm.coulmnType[_vm.params.operateType]],
                "columns-setting": false,
                "show-page": false,
                data: _vm.selectCheckCheckItem,
                "only-key": "uuid"
              },
              scopedSlots: _vm._u([
                {
                  key: "index",
                  fn: function(ref) {
                    var row = ref.row
                    var $index = ref.$index
                    return _c("div", {}, [_vm._v(_vm._s($index + 1))])
                  }
                },
                {
                  key: "warehouseBatchNumber",
                  fn: function(ref) {
                    var row = ref.row
                    return _c("div", {}, [
                      _vm._v(_vm._s(row.warehouseBatchNumber || "-"))
                    ])
                  }
                },
                {
                  key: "batchNumber",
                  fn: function(ref) {
                    var row = ref.row
                    return _c("div", {}, [
                      _vm._v(_vm._s(row.batchNumber || "-"))
                    ])
                  }
                },
                {
                  key: "sequenceNumber",
                  fn: function(ref) {
                    var row = ref.row
                    return _c("div", {}, [
                      _vm._v(_vm._s(row.sequenceNumber || "-"))
                    ])
                  }
                },
                {
                  key: "warehouseLocationName",
                  fn: function(ref) {
                    var row = ref.row
                    return _c("div", {}, [
                      _vm._v(_vm._s(row.warehouseLocationName || "-"))
                    ])
                  }
                }
              ])
            })
          ],
          1
        )
      ])
    ])
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }