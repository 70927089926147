var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "work-order-progress-container" }, [
    _c("div", { staticClass: "top" }, [
      _c("div", [
        _c(
          "div",
          { staticClass: "card-top" },
          [
            _c("dv-digital-flop", {
              staticStyle: { width: "425px", height: "80px" },
              attrs: {
                config: _vm.getDigitalConfig(_vm.digital.projectCount, [
                  "#fff",
                  "#fff"
                ])
              }
            })
          ],
          1
        ),
        _c("div", { staticClass: "card-bottom" }, [
          _c("span", [_vm._v(_vm._s(_vm.mode ? "订单总数" : "项目总数"))])
        ])
      ]),
      _c("div", [
        _c(
          "div",
          { staticClass: "card-top" },
          [
            _c("dv-digital-flop", {
              staticStyle: { width: "425px", height: "80px" },
              attrs: {
                config: _vm.getDigitalConfig(_vm.digital.workOrderCount, [
                  "#fff",
                  "#fff"
                ])
              }
            })
          ],
          1
        ),
        _vm._m(0)
      ]),
      _c("div", [
        _c(
          "div",
          { staticClass: "card-top" },
          [
            _c("dv-digital-flop", {
              staticStyle: { width: "425px", height: "80px" },
              attrs: {
                config: _vm.getDigitalConfig(_vm.digital.delayWorkOrderCount, [
                  "#fff",
                  "#fff"
                ])
              }
            })
          ],
          1
        ),
        _vm._m(1)
      ]),
      _c("div", [
        _c(
          "div",
          { staticClass: "card-top" },
          [
            _c("dv-digital-flop", {
              staticStyle: { width: "425px", height: "80px" },
              attrs: {
                config: _vm.getDigitalConfig(
                  _vm.digital.yesterdayReportWorkHours,
                  ["#fff", "#fff"]
                )
              }
            })
          ],
          1
        ),
        _vm._m(2)
      ])
    ]),
    _c(
      "div",
      { staticClass: "bottom" },
      [
        _c(
          "el-popover",
          {
            ref: "popover",
            attrs: { width: 207, offset: 20 },
            on: { show: _vm.popoverShowEvent },
            model: {
              value: _vm.showPopover,
              callback: function($$v) {
                _vm.showPopover = $$v
              },
              expression: "showPopover"
            }
          },
          [
            _c(
              "div",
              { staticClass: "popover-content-top" },
              [
                _c("el-button", { on: { click: _vm.cancel } }, [
                  _vm._v("取消")
                ]),
                _c("el-button", { on: { click: _vm.saveColumns } }, [
                  _vm._v("确定")
                ])
              ],
              1
            ),
            _c(
              "div",
              { staticClass: "popover-content-center" },
              [
                _c(
                  "el-checkbox",
                  {
                    on: { change: _vm.selectAllColumns },
                    model: {
                      value: _vm.allChecked,
                      callback: function($$v) {
                        _vm.allChecked = $$v
                      },
                      expression: "allChecked"
                    }
                  },
                  [_vm._v("全选")]
                ),
                _c(
                  "el-button",
                  {
                    staticClass: "invert",
                    attrs: { type: "text" },
                    on: { click: _vm.invertSelectColumns }
                  },
                  [
                    _c("img", {
                      attrs: {
                        src: require("@/assets/table_filter_images/fanxuan@2x.png"),
                        alt: "反选",
                        width: "13px"
                      }
                    }),
                    _c("span", [_vm._v("反选")])
                  ]
                )
              ],
              1
            ),
            _c(
              "div",
              {
                staticClass: "popover-content-footer",
                style: { maxHeight: _vm.popoverContentMaxHeight + "px" }
              },
              [
                _c(
                  "el-checkbox-group",
                  {
                    staticClass: "check-wrapper",
                    model: {
                      value: _vm.checkedList,
                      callback: function($$v) {
                        _vm.checkedList = $$v
                      },
                      expression: "checkedList"
                    }
                  },
                  [
                    _c(
                      "draggable",
                      _vm._b(
                        {
                          on: {
                            start: function($event) {
                              _vm.isDragging = true
                            },
                            end: function($event) {
                              _vm.isDragging = false
                            }
                          },
                          model: {
                            value: _vm.settingColumns,
                            callback: function($$v) {
                              _vm.settingColumns = $$v
                            },
                            expression: "settingColumns"
                          }
                        },
                        "draggable",
                        _vm.dragOptions,
                        false
                      ),
                      _vm._l(_vm.settingColumns, function(item) {
                        return _c(
                          "el-checkbox",
                          { key: item.key, attrs: { label: item.prop } },
                          [_vm._v(_vm._s(item.label))]
                        )
                      }),
                      1
                    )
                  ],
                  1
                )
              ],
              1
            )
          ]
        ),
        _c("div", { staticClass: "table-header" }, [
          _c("img", {
            staticClass: "img",
            attrs: { src: require("@/assets/screen/filter.png") }
          }),
          _c(
            "span",
            {
              directives: [
                {
                  name: "popover",
                  rawName: "v-popover:popover",
                  arg: "popover"
                }
              ],
              ref: "columnsSettingBtn"
            },
            [_vm._v("表头字段筛选")]
          )
        ]),
        _c("scroll-board", {
          style: { width: "100%", height: _vm.tableHeight + "px" },
          attrs: { config: _vm.getScrollBoardConfig },
          scopedSlots: _vm._u([
            {
              key: "reportProgress",
              fn: function(ref) {
                var index = ref.index
                return _c(
                  "div",
                  {},
                  [
                    _c("el-progress", {
                      staticStyle: { width: "130px" },
                      attrs: {
                        "stroke-width": 10,
                        percentage: _vm.tableData[index].reportProgress
                      }
                    })
                  ],
                  1
                )
              }
            },
            {
              key: "status",
              fn: function(ref) {
                var index = ref.index
                return _c(
                  "span",
                  {
                    staticClass: "status-board",
                    style: {
                      background:
                        _vm.productionStatus[_vm.tableData[index].status + 1]
                          .color
                    }
                  },
                  [
                    _vm._v(
                      _vm._s(
                        _vm.productionStatus[_vm.tableData[index].status + 1]
                          .name
                      )
                    )
                  ]
                )
              }
            },
            {
              key: "progress",
              fn: function(ref) {
                var index = ref.index
                return [
                  _c("div", [
                    _c(
                      "div",
                      {
                        staticClass: "progress-line-wrapper",
                        style: {
                          position: "relative",
                          left: -_vm.getLeft(index) + "px"
                        }
                      },
                      _vm._l(_vm.allProcedureList(index), function(
                        item,
                        index2
                      ) {
                        return _c(
                          "div",
                          {
                            key: index2,
                            staticClass: "progress-wrap wait-procedure"
                          },
                          [
                            _c("custom-progress", {
                              attrs: {
                                type: "circle",
                                percentage: item.reportProgress,
                                color: "#607FFF",
                                "stroke-w": "#999",
                                width: 70,
                                "show-text": false
                              }
                            }),
                            _c("div", { staticClass: "progress-text" }, [
                              _c("span", [
                                _vm._v(_vm._s(item.reportProgress) + "%")
                              ])
                            ]),
                            _c(
                              "div",
                              { staticClass: "procedure-name ellipsis" },
                              [_vm._v(_vm._s(item.procedureName))]
                            )
                          ],
                          1
                        )
                      }),
                      0
                    )
                  ])
                ]
              }
            }
          ])
        })
      ],
      1
    )
  ])
}
var staticRenderFns = [
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "card-bottom" }, [
      _c("span", [_vm._v("工单总数")])
    ])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "card-bottom" }, [
      _c("span", [_vm._v("延期工单数")])
    ])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "card-bottom" }, [
      _c("span", [_vm._v("昨日报工工时")])
    ])
  }
]
render._withStripped = true

export { render, staticRenderFns }