<template>
  <div class="inspection-plan-detail-wrappers">
    <div class="inspection-plan-detail-header flex-sbc">
      <div class="left" @click="$router.back()">
        <img src="@/assets/information/procedure/左滑@2x.png" style="width: 8px">
        <span style="padding-left: 10px;cursor: pointer">{{ messages[params.type + 'O'] + typeList[params.operateType] }}</span>
      </div>
    </div>
    <div class="inspection-plan-detail-body">
      <div class="detail-card">
        <div class="card-tit">基本信息</div>
        <div class="card-bot">
          <MFormBuilder ref="formBuild" :form-list="baseFormList[params.operateType]" :form-data="baseFormData">
            <div slot="saveBtn">
              <el-button size="small" type="primary" style="margin-top: 42px" :disabled="isTrue" @click="saveItem">确认</el-button>
            </div>
            <div slot="orderNumber">
              <el-input v-if="!selIsShow" v-model="orderNumber" placeholder="请输入关联单号" @change="onChange" />
              <el-select v-else v-model="orderNumber" placeholder="请选择关联销售订单" filterable style="width:100%" @change="onChange">
                <el-option v-for=" item in orderNumberOptions" :key="item.code" :label="item.code" :value="item.code" />
              </el-select>
            </div>
          </MFormBuilder>
        </div>
      </div>
      <div class="detail-card">
        <div class="card-tit">{{ [0, 1].includes(+params.operateType) ?'散件' : '物料' }}</div>
        <div class="card-bot">
          <el-button v-if="![4].includes(+params.operateType)" size="small" type="primary" style="margin-bottom: 10px" @click="addCheckItem">{{ [0].includes(+params.operateType) ? '添加物料': '选择物料' }}</el-button>
          <el-button v-if="[4, 5].includes(+params.operateType) " size="small" type="primary" style="margin-bottom: 10px" @click="addCheckItem(1)">添加物料</el-button>
          <MTable
            ref="pointCheckTable"
            :height="300"
            :columns="columns[coulmnType[params.operateType]]"
            :columns-setting="false"
            :show-page="false"
            :data="selectCheckCheckItem"
            :only-key="'uuid'"
          >
            <div slot="index" slot-scope="{ $index }">{{ $index + 1 }}</div>
            <div slot="quantity" slot-scope="{ row }">
              <template v-if="[4, 5].includes(+params.operateType)">
                <div v-if="+row.isSequenceNumber === 1 && row.isAddMaterials === 1">{{ row.quantity }}</div>
                <div v-else style="display: flex;align-items: center;justify-content: flex-start;">
                  <span style="color: #f00;width: 10px;">*</span>
                  <el-input-number v-model="row.quantity" :min="0" controls-position="right" :step="1" style="width: 100%" @blur="row.quantity=sliceVal(row.quantity,5)" />
                </div>
              </template>
              <template v-else>
                <div v-if="+row.isSequenceNumber === 1">{{ row.quantity }}</div>
                <div v-else style="display: flex;align-items: center;justify-content: flex-start;">
                  <span style="color: #f00;width: 10px;">*</span>
                  <el-input-number v-model="row.quantity" :disabled="row.isEdit && [1, 2, 3].includes(+params.operateType)" :min="0" :max="[0, 2, 3, 4, 5].includes(+params.operateType) ? undefined : +row.currentQuantity" controls-position="right" :step="1" style="width: 100%" @blur="row.quantity=sliceVal(row.quantity,5)" />
                </div>
              </template>
            </div>
            <div slot="specifications" slot-scope="{ row}">{{ row.specifications }}</div>
            <div slot="warehouseBatchNumber" slot-scope="{ row }">
              <span v-if="row.isAddMaterials === 0">{{ row.warehouseBatchNumber || '-' }}</span>
              <template v-else>
                <div v-if="+row.isBatchManager === 1" style="display: flex;align-items: center;justify-content: flex-start;">
                  <el-input v-model="row.warehouseBatchNumber" />
                </div>
                <span v-else>-</span>
              </template>
            </div>
            <div slot="batchNumber" slot-scope="{ row }">
              <span v-if="row.isAddMaterials === 0">{{row.batchNumber || '-'}}</span>
              <template v-else>
                <div v-if="+row.isBatchManager === 1" style="display: flex;align-items: center;justify-content: flex-start;">
                  <span style="color: #f00;width: 10px;">*</span>
                  <el-input v-model="row.batchNumber" ></el-input>
                </div>
                <span v-else>-</span>
              </template>
            </div>
            <div slot="sequenceNumber" slot-scope="{ row }">
              <template v-if="row.isAddMaterials === 0">{{ row.sequenceNumber || '-' }}</template>
              <template v-else>
                <template v-if="+params.operateType === 0 || +params.operateType === 5 || +params.operateType === 4">
                  <div v-if="+row.isSequenceNumber === 1" style="display: flex;align-items: center;justify-content: flex-start;">
                    <span style="color: #f00;width: 10px;">*</span>
                    <el-input v-model="row.sequenceNumber" />
                  </div>
                  <span v-else>-</span>
                </template>
                <span v-else>{{ row.sequenceNumber || '-' }}</span>
              </template>
            </div>
            <div slot="warehouseName" slot-scope="{ row, $index }" style="display: flex;align-items: center;justify-content: flex-start;">
              <template v-if="row.isAddMaterials === 0">{{ row.warehouseName }}</template>
              <template v-else>
                <span style="color: #f00;width: 10px;">*</span>
                <el-select v-model="row.warehouseId" placeholder="请选择">
                  <el-option
                    v-for="item in allWarehouseList"
                    :key="item.id"
                    :label="item.name"
                    :value="item.id"
                    @click.native="selectWarehouse(item, $index)"
                  />
                </el-select>
              </template>
            </div>
            <!-- 物料盘点库位 -->
            <div slot="materialWarehouseLocationName" slot-scope="{ row, }" style="display: flex;align-items: center;justify-content: flex-start;">
              <template v-if="row.isAddMaterials === 0">{{ row.warehouseLocationName }}</template>
              <template v-else>
                <span v-if="row.isAddMaterialsLocation === 1" style="color: #f00;width: 10px;">*</span>
                <el-cascader
                  :key="row.keyValue"
                  v-model="row.warehouseLocationIdList"
                  :options="row.materialWarehouseLocationList"
                  :props="{value: 'id', label: 'name'}"
                />
              </template>
            </div>
            <div slot="warehouseLocationName" slot-scope="{ row, }" style="display: flex;align-items: center;justify-content: flex-start;">
              <template v-if="row.isAddMaterials === 0">{{ row.warehouseLocationName }}</template>
              <template v-else>
                <span v-if="+isLocation === 1" style="color: #f00;width: 10px;">*</span>
                <el-cascader
                  v-model="row.warehouseLocationIdList"
                  :options="warehouseLocationList"
                  :props="{value: 'id', label: 'name'}"
                />
              </template>
            </div>
            <!-- 调拨、移库的库位 -->
            <div slot="inWarehouseLocationName" slot-scope="{ row, }" style="display: flex;align-items: center;justify-content: flex-start;">
              <span style="color: #f00;width: 10px;">*</span>
              <el-cascader
                :key="row.keyValue"
                v-model="row.warehouseLocationIdList"
                :options="fixArr(row.warehouseLocationId)"
                :props="{value: 'id', label: 'name'}"
              />
            </div>
            <div slot="action" slot-scope="{ row, $index }">
              <el-button v-if="params.operateType === 0" type="text" @click="copyRow(row, $index)">复制</el-button>
              <el-button v-if="([0, 1, 2, 3].includes(+params.operateType) && !row.isEdit) || ([4, 5].includes(+params.operateType) && +row.isAddMaterials === 1) " type="text" @click="delRow(row, $index)">移除</el-button>
              <el-button v-if="[1, 2, 3].includes(+params.operateType) && row.isEdit" type="text" @click="canceldelRow(row, $index)">撤销移除</el-button>
            </div>
          </MTable>
        </div>
      </div>
      <!--<div v-if="[0, 1].includes(+params.operateType)" class="detail-card">-->
        <!--<div class="card-tit">整件</div>-->
        <!--<div class="card-bot">-->
          <!--<el-button v-if="[0].includes(+params.operateType)" size="small" type="primary" style="margin-bottom: 10px" @click="addCheckItem(2)">添加物料</el-button>-->
          <!--<el-button v-else size="small" type="primary" style="margin-bottom: 10px" @click="addCheckItem(5)">选择物料</el-button>-->
          <!--<MTable-->
            <!--ref="pointCheckTables"-->
            <!--:height="300"-->
            <!--:columns="[0].includes(+params.operateType) ? initAllInMaterialColumn : initAllOutMaterialColumn"-->
            <!--:columns-setting="false"-->
            <!--:show-page="false"-->
            <!--:data="selectCheckCheckItemAll"-->
            <!--:only-key="'uuid'"-->
          <!--&gt;-->
            <!--<div slot="index" slot-scope="{ $index }">{{ $index + 1 }}</div>-->

            <!--<div slot="containerNo" slot-scope="{ row }">-->
              <!--<div style="display: flex;align-items: center;justify-content: flex-start;">-->
                <!--<span style="color: #f00;width: 10px;">*</span>-->
                <!--<el-input v-model="row.containerNo" />-->
              <!--</div>-->
            <!--</div>-->
            <!--<div slot="containerName" slot-scope="{ row }">-->
              <!--<div style="display: flex;align-items: center;justify-content: flex-start;">-->
                <!--<span style="color: #f00;width: 10px;">*</span>-->
                <!--<el-input v-model="row.containerName" />-->
              <!--</div>-->
            <!--</div>-->
            <!--<div slot="capacity" slot-scope="{ row }">-->
              <!--<div style="display: flex;align-items: center;justify-content: flex-start;">-->
                <!--<span style="color: #f00;width: 10px;">*</span>-->
                <!--<el-input-number v-model="row.capacity" :min="0" controls-position="right" :step="1" style="width: 100%" @blur="row.capacity=sliceVal(row.capacity,5)" />-->
              <!--</div>-->
            <!--</div>-->
            <!--<div slot="containerQuantity" slot-scope="{ row }">-->
              <!--<div style="display: flex;align-items: center;justify-content: flex-start;">-->
                <!--<span style="color: #f00;width: 10px;">*</span>-->
                <!--<el-input-number v-model="row.containerQuantity" :disabled="row.isEdit && [1].includes(+params.operateType)" :min="0" :max="[0].includes(+params.operateType) ? undefined : +row.currentQuantity" controls-position="right" :step="1" style="width: 100%" @blur="row.containerQuantity=sliceVal(row.containerQuantity,5)" />-->
              <!--</div>-->
            <!--</div>-->
            <!--<div slot="warehouseBatchNumber" slot-scope="{ row }">-->
              <!--<div v-if="+params.operateType === 0 && +row.isBatchManager === 1" style="display: flex;align-items: center;justify-content: flex-start;">-->
                <!--<el-input v-model="row.warehouseBatchNumber" />-->
              <!--</div>-->
              <!--<span v-else>{{ row.warehouseBatchNumber || '-' }}</span>-->
            <!--</div>-->
            <!--<div slot="batchNumber" slot-scope="{ row }">-->
              <!--<div v-if="+params.operateType === 0 && +row.isBatchManager === 1" style="display: flex;align-items: center;justify-content: flex-start;">-->
                <!--<span style="color: #f00;width: 10px;">*</span>-->
                <!--<el-input v-model="row.batchNumber" ></el-input>-->
              <!--</div>-->
              <!--<span v-else>{{row.batchNumber || '-'}}</span>-->
            <!--</div>-->
            <!--<div slot="sequenceNumber" slot-scope="{ row }">-->
              <!--<div v-if="+params.operateType === 0 && +row.isSequenceNumber === 1" style="display: flex;align-items: center;justify-content: flex-start;">-->
                <!--<span style="color: #f00;width: 10px;">*</span>-->
                <!--<el-input v-model="row.sequenceNumber" ></el-input>-->
              <!--</div>-->
              <!--<span v-else>{{row.sequenceNumber || '-'}}</span>-->
            <!--</div>-->
            <!--<div slot="warehouseLocationName" slot-scope="{ row, }" style="display: flex;align-items: center;justify-content: flex-start;">-->
              <!--<template v-if="+params.operateType === 1">{{ row.warehouseLocationName }}</template>-->
              <!--<template v-else>-->
                <!--<span style="color: #f00;width: 10px;" v-if="+isLocation === 1">*</span>-->
                <!--<el-cascader-->
                  <!--v-model="row.warehouseLocationIdList"-->
                  <!--:options="warehouseLocationList"-->
                  <!--:props="{value: 'id', label: 'name'}"-->
                <!--/>-->
              <!--</template>-->
            <!--</div>-->

            <!--<div slot="action" slot-scope="{ row, $index }">-->
              <!--<el-button v-if="params.operateType === 0" type="text" @click="allCopyRow(row, $index)">复制</el-button>-->
              <!--<el-button v-if="[0, 1].includes(+params.operateType) && !row.isEdit" type="text" @click="AllDelRow(row, $index)">移除</el-button>-->
              <!--<el-button v-if="[0, 1].includes(+params.operateType) && row.isEdit" type="text" @click="cancelAllDelRow(row, $index)">撤销移除</el-button>-->
            <!--</div>-->
          <!--</MTable>-->
        <!--</div>-->
      <!--</div>-->
    </div>
    <div class="order-footer">
      <div class="footer-le" />
      <div class="footer-ri">
        <el-button size="small" @click="returns">取消</el-button>
        <el-button type="primary" :loading="buttonLoading" size="small" @click="switchOrSubmit">确定</el-button>
      </div>
    </div>
    <select-materials
      ref="selectMaterials"
      :visible.sync="showMaterials"
      :is-add="true"
      :title="'添加物料'"
      @submitForm="submitMaterials"
      @addMaterial="addMaterial"
    />
    <!-- 新增物料 -->
    <edit-materials ref="editMaterials" @onSuccess="onSuccess" />
    <!-- 物料库存 -->
    <select-materials-inventory
      ref="selectMaterialInventory"
      :visible.sync="showMaterialInventory"
      :conditions="conditions"
      :warehouse-id="selectWarehouseId"
      :select-material-inventorys="selectMaterials"
      :operate-type="operateTypes"
      :is-container="isContainer"
      @submitForm="submitMaterialInventory"
    />
  </div>
</template>

<script>
import EditMaterials from '@/views/information/materials/components/edit-materials'
import SelectMaterials from '@/components/SelectMaterials/SelectMaterials.vue'
import SelectMaterialsInventory from '@/components/SelectMaterialsInventory/SelectMaterialsInventory.vue'
import conventionMixin from '@/mixin/conventional-page'
import api from '@/api/xiewei/spare-part/warehouse'
import bApi from '@/api/information/print-label/batch-number'

import { getUUid, setTreeData, setTreeDataDisabled } from '@/utils'
import { AllInMaterialColumn, AllOutMaterialColumn, InMaterialColumn, OutMaterialColumn, AllocationMaterialColumn, TransferMaterialColumn, WarehouseMaterialColumn, MaterialColumn, getFunctionList } from './columns'
import { Encrypt } from '@/utils/sercet'
import sApi from '@/api/xiewei/spare-part/set'
import warehouseApi from '@/api/information/warehouse/model'
import { inFormList, outFormList, allocationFormList, transferFormList, warehouseFormList, materialFormList } from './form-list'
import inventoryApi from '@/api/warehouse/inventory'
import fieldApi from '@/api/xiewei/field/field'
import apiSales from '@/api/production/salesManage'
import { sliceVal } from '@/utils'

export default {
  // eslint-disable-next-line vue/name-property-casing
  name: 'WarehouseOperate',
  components: { SelectMaterialsInventory, SelectMaterials, EditMaterials },
  mixins: [conventionMixin],
  data() {
    return {
      api,
      initAllInMaterialColumn: AllInMaterialColumn,
      initAllOutMaterialColumn: AllOutMaterialColumn,
      InMaterialColumn,
      OutMaterialColumn,
      AllocationMaterialColumn,
      TransferMaterialColumn,
      WarehouseMaterialColumn,
      MaterialColumn,
      selectWarehouseId: undefined,
      columns: {
        InMaterialColumn: [],
        OutMaterialColumn: [],
        AllocationMaterialColumn: [],
        TransferMaterialColumn: [],
        WarehouseMaterialColumn: [],
        MaterialColumn: []
      },
      baseFormList: [inFormList, outFormList, allocationFormList, transferFormList, warehouseFormList, materialFormList],
      baseFormData: {},
      selectCheckCheckItem: [],
      warehouseLocationList: [],
      fullScreen: false,
      showMaterials: false,
      params: {},
      buttonLoading: false,
      messages: {
        add: '新增成功',
        modify: '修改成功',
        copy: '复制成功',
        viewO: '查看',
        addO: '新增',
        modifyO: '修改'
      },
      coulmnType: ['InMaterialColumn', 'OutMaterialColumn', 'AllocationMaterialColumn', 'TransferMaterialColumn', 'WarehouseMaterialColumn', 'MaterialColumn'],
      typeList: ['入库', '出库', '调拨', '移库', '仓库盘点', '物料盘点'],
      allWarehouseList: [],
      warehouseTypeList: [],
      saveButton: false,
      isLocation: 1,
      initWarehouseLocationList: [],
      showMaterialInventory: false,
      conditions: [],
      isTrue: true,
      currentWarehouseId: '',
      selectMaterialInventorys: [],
      operateTypes: 2,
      selectCheckCheckItemAll: [],
      isAll: false,
      isContainer: 0,
      selectMaterialInventorysAll: [],
      selectMaterials: [],
      selIsShow: false,
      orderNumber: '',
      orderNumberOptions: [],
      warehouseTypeListMap: {}
    }
  },
  beforeMount() {
    getFunctionList({
      inWarehousChange: this.inWarehousChange,
      warehousChange: this.warehousChange,
      warehousLocationChange: this.warehousLocationChange,
      inventoryWarehousChange: this.inventoryWarehousChange,
      outInWarehouse: this.outInWarehouse
    })
  },
  async mounted() {
    this.params = this.$route.query
    this.saveButton = this.params.type !== 'view'
    this.$refs.formBuild.formLists = this.baseFormList[this.params.operateType]
    this.$refs.formBuild.setSomeData()
    await this.getWarehouseList()
    this.getColumn()
    if (this.params.type === 'modify') {
      // setTimeout(() => {
      this.getWarehouseOperationSheetDetail(this.params.id)
      // }, 500)
    }
    if (this.params.type !== 'view') {
      this.createFieldCode()
    }
  },
  methods: {
    sliceVal,
    outInWarehouse() {

    },
    onInput(key, val, row, num = 3) {
      const valStr = val + ''
      const decimalLength = valStr.split('.')[1]?.length
      if (decimalLength > num) {
        row[key] = valStr.slice(0, valStr.length - (decimalLength - num)) * 1
      }
    },
    async createFieldCode() {
      const newData = new Map([
        ['0', '入库单号'],
        ['1', '出库单号'],
        ['2', '调拨单号'],
        ['3', '移库单号'],
        ['4', '盘点单号'],
        ['5', '盘点单号']
      ])
      const res = await fieldApi.getFieldIsExist({
        numberVersion: newData.get(this.params.operateType.toString())
      })
      if (res && +res === 0) {
        if (this.params.type === 'add') {
          this.$refs.formBuild.setForm({
            number: '系统生成',
            orderNumber: ''
          })
        }
        this.baseFormList[this.params.operateType][0].props.disabled = true
      }
    },
    fixArr(id) {
      const data = this.initWarehouseLocationList.filter(item => item.id !== +id)
      return +this.params.operateType === 3 ? setTreeData(data) : this.warehouseLocationList
    },
    // 获取物料库存数据
    async getMatertialsData() {
      const { form } = this.$refs.formBuild
      const res = await inventoryApi.getWarehouseMaterialsDetailsIsContainerList({
        isContainer: 0,
        warehouseId: form.warehouseId || '',
        warehouseLocationIds: form.warehouseLocationIds ? form.warehouseLocationIds.map(item => this._.last(item)).join('^') : ''
      })
      if (res) {
        const data = this.selectCheckCheckItem.filter(item => +item.isAddMaterials === 1)
        const list = res.map(item => {
          return {
            ...item,
            isAddMaterials: 0,
            quantity: +item.isSequenceNumber === 1 ? 1 : +this.params.operateType === 4 ? item.currentQuantity : undefined,
            warehouseLocationIdList: '',
            uuid: getUUid()
          }
        })
        this.selectCheckCheckItem = [...list, ...data]
      }
    },
    saveItem() {
      this.getMatertialsData()
      this.getAllNodesByWarehouseId(this.currentWarehouseId)
      this.isLocation = this.allWarehouseList.filter(item => item.id === +this.currentWarehouseId)[0].isLocation
      this.isTrue = true
    },
    inventoryWarehousChange(val) {
      this.isTrue = false
      this.getAllNodesByWarehouseId(val, 1)
      this.currentWarehouseId = val
    },
    warehousLocationChange(val) {
      this.isTrue = false
    },
    inWarehousChange(val) {
      if (+this.params.operateType === 2) {
        this.isLocation = this.allWarehouseList.filter(item => item.id === val)[0].isLocation
        if (+this.isLocation === 1) {
          this.getAllNodesByWarehouseId(val)
          this.columns.AllocationMaterialColumn = this.AllocationMaterialColumn
        } else {
          const newColumns = this._.cloneDeep(this.AllocationMaterialColumn)
          newColumns.splice(9, 1)
          this.columns.AllocationMaterialColumn = newColumns
        }

        if (this.selectCheckCheckItem.length > 0) {
          this.selectCheckCheckItem = this.selectCheckCheckItem.map((item, index) => {
            return {
              ...item,
              inWarehouseLocationId: '',
              inWarehouseLocationName: '',
              warehouseLocationIdList: [],
              keyValue: ++index
            }
          })
        }
      }
    },
    async selectWarehouse(row, idx) {
      const res = await warehouseApi.getAllNodesByWarehouseId(row.id)
      if (res) {
        const data = res.length > 1 ? res : []
        this.$set(this.selectCheckCheckItem[idx], 'isAddMaterialsLocation', row.isLocation)
        this.$set(this.selectCheckCheckItem[idx], 'keyValue', ++this.selectCheckCheckItem[idx].keyValue)
        this.$set(this.selectCheckCheckItem[idx], 'materialWarehouseLocationList', setTreeData(data))
        this.$set(this.selectCheckCheckItem[idx], 'initMaterialWarehouseLocationList', data)
      }
    },
    async submitMaterialInventory(val) {
      const arr = val.map((item) => {
        return {
          ...item,
          warehouseOperationDetailsKey: item.key,
          isContainer: [1].includes(+this.params.operateType) && +this.isContainer === 1 ? 1 : 0,
          selectable: [1, 5].includes(+this.params.operateType) ? true : !!([2, 3].includes(+this.params.operateType) && +item.isSequenceNumber === 1)
        }
      })
      // 出库-整件
      if ([1].includes(+this.params.operateType) && this.isAll) {
        const allData = val.map(item => {
          return {
            ...item,
            isAddMaterials: 0,
            containerQuantity: undefined,
            materialWarehouseLocationList: [],
            initMaterialWarehouseLocationList: [],
            warehouseLocationIdList: '',
            keyValue: 0,
            isEdit: false,
            uuid: getUUid(),
            warehouseOperationDetailsKey: item.key,
            specifications:item.materialsSpecifications||item.specifications
          }
        })
        this.selectCheckCheckItemAll = [...this.selectCheckCheckItemAll, ...allData]
        this.showMaterialInventory = false
        this.selectMaterialInventorysAll = [...this.selectMaterialInventorysAll, ...arr]
        return false
      }
      this.selectMaterialInventorys = [...this.selectMaterialInventorys, ...arr]
      let data = []
      if ([5].includes(+this.params.operateType)) {
        const materialsCodes = val.map(item => item.materialsCode)
        const res = await inventoryApi.getInventoryDetailList({ isContainer: 0, materialsCodes: materialsCodes.join('^') })
        if (res) {
          data = res.map(item => {
            return {
              ...item,
              isAddMaterials: +this.params.operateType === 2 ? 1 : 0,
              quantity: +item.isSequenceNumber === 1 ? 1 : +this.params.operateType === 5 ? item.currentQuantity : undefined,
              materialWarehouseLocationList: [],
              initMaterialWarehouseLocationList: [],
              warehouseLocationIdList: '',
              keyValue: 0,
              uuid: getUUid()
            }
          })
        }
      } else {
        data = val.map(item => {
          return {
            ...item,
            isAddMaterials: +this.params.operateType === 2 ? 1 : 0,
            quantity: +item.isSequenceNumber === 1 ? 1 : undefined,
            materialWarehouseLocationList: [],
            initMaterialWarehouseLocationList: [],
            warehouseLocationIdList: '',
            keyValue: 0,
            isEdit: false,
            warehouseOperationDetailsKey: item.key,
            specifications:item.materialsSpecifications||item.specifications,
            uuid: getUUid()
          }
        })
      }

      this.selectCheckCheckItem = [...this.selectCheckCheckItem, ...data]
      this.showMaterialInventory = false
    },
    delRow(row, index) {
      if (this.params.type === 'modify' && [1, 2, 3].includes(+this.params.operateType)) {
        this.$set(this.selectCheckCheckItem[index], 'isEdit', true)
        const idx = this.selectMaterialInventorys.findIndex(item => item.key === row.warehouseOperationDetailsKey)
        this.selectMaterialInventorys[idx].isEdit = true
      } else {
        this.$set(this.selectCheckCheckItem[index], 'isEdit', false)
        this.selectCheckCheckItem.splice(index, 1)
        this.selectMaterialInventorys = this.selectMaterialInventorys.filter(item => item.key !== row.warehouseOperationDetailsKey)
      }
    },
    copyRow(row, index) {
      const data = this._.cloneDeep(row)
      data.uuid = getUUid()
      this.selectCheckCheckItem.splice(index + 1, 0, data)
    },
    allCopyRow(row, index) {
      const data = this._.cloneDeep(row)
      data.uuid = getUUid()
      this.selectCheckCheckItemAll.splice(index + 1, 0, data)
    },
    AllDelRow(row, index) {
      if (this.params.type === 'modify' && [1].includes(+this.params.operateType)) {
        this.$set(this.selectCheckCheckItemAll[index], 'isEdit', true)
        const idx = this.selectMaterialInventorysAll.findIndex(item => item.key === row.warehouseOperationDetailsKey)
        this.selectMaterialInventorysAll[idx].isEdit = true
      } else {
        this.$set(this.selectCheckCheckItemAll[index], 'isEdit', false)
        this.selectCheckCheckItemAll.splice(index, 1)
        this.selectMaterialInventorysAll = this.selectMaterialInventorysAll.filter(item => item.key !== row.warehouseOperationDetailsKey)
      }
    },
    canceldelRow(row, index) {
      this.selectCheckCheckItem[index].isEdit = false
      const idx = this.selectMaterialInventorys.findIndex(item => item.key === row.warehouseOperationDetailsKey)
      this.selectMaterialInventorys[idx].isEdit = false
    },
    cancelAllDelRow(row, index) {
      this.selectCheckCheckItemAll[index].isEdit = false
      const idx = this.selectMaterialInventorysAll.findIndex(item => item.key === row.warehouseOperationDetailsKey)
      this.selectMaterialInventorysAll[idx].isEdit = false
    },
    returns() {
      this.$router.push({ name: 'warehouseRecord' })
    },
    warehousChange(val, type) {
      if ([0, 1, 3].includes(+this.params.operateType)) {
        this.isLocation = this.allWarehouseList.filter(item => item.id === val)[0].isLocation
      }
      if (+this.params.operateType === 0) {
        const isLocation = this.allWarehouseList.filter(item => item.id === val)[0].isLocation
        if (+isLocation === 1) {
          this.getAllNodesByWarehouseId(val)
          this.columns.InMaterialColumn = this.InMaterialColumn
          this.initAllInMaterialColumn = AllInMaterialColumn
        } else {
          // 散件无库位时去掉列表显示
          const newColumns = this._.cloneDeep(this.InMaterialColumn)
          newColumns.splice(9, 1)
          this.columns.InMaterialColumn = newColumns
          // 整件无库位时去掉列表显示
          const newColumnsAll = this._.cloneDeep(AllInMaterialColumn)
          newColumnsAll.splice(12, 1)
          this.initAllInMaterialColumn = newColumnsAll
        }
        if (this.selectCheckCheckItem.length > 0) {
          this.selectCheckCheckItem = this.selectCheckCheckItem.map(item => {
            return {
              ...item,
              warehouseLocationId: '',
              warehouseLocationName: ''
            }
          })
        }
        if (this.selectCheckCheckItemAll.length > 0) {
          this.selectCheckCheckItemAll = this.selectCheckCheckItemAll.map(item => {
            return {
              ...item,
              warehouseLocationId: '',
              warehouseLocationName: ''
            }
          })
        }
      }
      if (+this.params.operateType === 3) {
        this.getAllNodesByWarehouseId(val)
      }
      if (!type && [1, 2, 3].includes(+this.params.operateType)) {
        this.selectCheckCheckItem = []
        this.selectCheckCheckItemAll = []
        this.selectMaterialInventorys = []
        this.selectMaterialInventorysAll = []
      }
    },
    async getAllNodesByWarehouseId(id, type) {
      const res = await warehouseApi.getAllNodesByWarehouseId(id)
      if (res) {
        if (+type === 1) {
          this.baseFormList[4][2].props.options = setTreeDataDisabled(res)
        }
        this.initWarehouseLocationList = res.length > 1 ? res : []
        this.warehouseLocationList = res.length > 1 ? setTreeData(res) : []
      }
    },
    async addCheckItem (val) {
      this.isAll = +val === 2 || +val === 5
      this.isContainer = +this.params.operateType === 1 && val === 5 ? 1 : 0
      const { form } = this.$refs.formBuild
      if ((+val === 1 && +this.params.operateType !== 4) || (form.warehouseId && (+this.params.operateType === 0 || +this.params.operateType === 4))) {
        this.showMaterials = true
        return false
      }
      if (form.warehouseId && +this.params.operateType === 1) {
        this.selectWarehouseId = form.warehouseId
        if (val === 5) {
          this.selectMaterials = this.selectMaterialInventorysAll
        } else {
          this.selectMaterials = this.selectMaterialInventorys
        }
        this.showMaterialInventory = true
        return false
      }
      if (form.warehouseId && (+this.params.operateType === 2 || +this.params.operateType === 3)) {
        this.selectWarehouseId = form.warehouseId
        this.selectMaterials = this.selectMaterialInventorys
        this.showMaterialInventory = true
        return false
      }
      if (+this.params.operateType === 5) {
        this.operateTypes = 1
        this.selectMaterials = this.selectMaterialInventorys
        this.showMaterialInventory = true
        return false
      }
      return this.$message.error('请选择仓库')
    },
    addMaterial() {
      this.$refs.editMaterials.add()
    },
    onSuccess() {
      this.$refs.selectMaterials.updateTable()
    },
    submitMaterials(val) {
      // 整件
      if (this.isAll && +this.params.operateType === 0) {
        const allData = val.map(item => {
          return {
            ...item,
            batchNumber: '',
            isSequenceNumber: item.sequenceNumber,
            sequenceNumber: '',
            quantity: undefined,
            warehouseLocationId: '',
            warehouseLocationName: '',
            isContainer: 1,
            uuid: getUUid()
          }
        })
        this.addBatch(allData).then(res=>{
          let newData =  allData.map((item,index) => {
          item.batchNumber = res[index].batchNumber
          return item
        });
          this.selectCheckCheckItemAll = [...this.selectCheckCheckItemAll, ...newData]
          this.showMaterials = false
        })
        return false
      }
      // 散件
      let data
      if (+this.params.operateType === 4 || +this.params.operateType === 5) {
        data = val.map(item => {
          return {
            ...item,
            materialsId: item.id,
            materialsName: item.name,
            materialsCode: item.code,
            materialsSpecifications: item.specifications,
            materialsType: item.materialsType,
            materialsUnitId: item.mainUnitId,
            materialsUnit: item.mainUnitName,
            batchNumber: '',
            isSequenceNumber: item.sequenceNumber,
            sequenceNumber: '',
            quantity: item.sequenceNumber === 1 ? 1 : undefined,
            warehouseLocationId: '',
            warehouseLocationName: '',
            currentQuantity: 0,
            warehouseId: '',
            warehouseName: '',
            keyValue: 0,
            isAddMaterials: 1,
            isAddMaterialsLocation: 1,
            uuid: getUUid()
          }
        })
      } else {
        data = val.map(item => {
          return {
            ...item,
            batchNumber: '',
            isSequenceNumber: item.sequenceNumber,
            sequenceNumber: '',
            quantity: item.sequenceNumber === 1 ? 1 : undefined,
            warehouseLocationId: '',
            warehouseLocationName: '',
            isContainer: 0,
            uuid: getUUid()
          }
        })
      }
      this.addBatch(data).then(res=>{
       let newData =  data.map((item,index) => {
          item.batchNumber = res[index].batchNumber
          return item
        });
        this.selectCheckCheckItem = [...this.selectCheckCheckItem, ...newData]
        this.showMaterials = false
      })

    },
    // 获取入库-出库类型
    async getTypes() {
      const search = [
        {
          id: getUUid(),
          fieldName: 'type',
          fieldType: 'number',
          fieldValue: +this.params.operateType === 0 ? 0 : 1,
          operator: 'in'
        }
      ]
      const res = await sApi.getOutInWarehouseTypeList({
        searchVal: Encrypt(JSON.stringify([...search]))
      })
      if (res) {
        this.warehouseTypeList = res ? res.filter(item => item.isShow === 1) : []
        this.warehouseTypeListMap = this.warehouseTypeList.reduce((pre, cur) => ({ ...pre, [cur.id]: cur.name }), {})
        this.baseFormList[this.params.operateType][1].children[0].options = res ? res.filter(item => item.isShow === 1) : []
        this.getOrderNumberOptions()
        // 绑定选项改变事件
        if (this.params.operateType === 1) { // 出库
          this.baseFormList[1][1].on.change = (val) => {
            this.selIsShow = this.warehouseTypeListMap[val] === '销售发货出库'
            this.baseFormList[1][2].label = this.selIsShow ? '关联销售订单' : '关联单号'
            this.baseFormList[1][2].required = this.selIsShow
            this.$set(this.baseFormList[1][2], 'required', this.selIsShow)
            this.orderNumber = ''
            this.$refs.formBuild.rules.orderNumber = [
              {
                'required': this.selIsShow,
                'message': '请选择关联销售订单',
                'trigger': 'blur'
              }
            ]
            this.$refs.formBuild.setForm({
              orderNumber: this.orderNumber
            })
            this.$refs.formBuild.formRefs().validateField('orderNumber')
          }
        }
      }
    },

    // 获取关联单号下拉
    async getOrderNumberOptions() {
      const res = await apiSales.getActiveSalesOrderList()
      if (res) this.orderNumberOptions = res
    },

    // 获取仓库
    async getWarehouseList() {
      const res = await warehouseApi.getRepositoryModelList({
        searchVal: Encrypt(JSON.stringify([...this.defaultSearch, {
          id: getUUid(),
          fieldName: 'type',
          fieldType: 'number',
          fieldValue: 0,
          operator: 'eq'
        }]))
      })
      if (res) {
        this.allWarehouseList = res
        if ([0, 1, 2].includes(+this.params.operateType)) {
          this.baseFormList[this.params.operateType][3].children[0].options = res
        }
        if (+this.params.operateType === 2) {
          this.baseFormList[this.params.operateType][2].children[0].options = res
        }
        if ([4].includes(+this.params.operateType)) {
          this.baseFormList[this.params.operateType][1].children[0].options = res
        }
        if ([3].includes(+this.params.operateType)) {
          const data = res.filter(item => item.childrenIdList && +item.isLocation === 1)
          this.baseFormList[this.params.operateType][1].children[0].options = data
          this.allWarehouseList = data
        }
      }
    },
    async selectName(item) {
      await this.$refs.formBuild.setForm({
        checkTypeId: item.id,
        checkType: item.name
      })
      this.$refs.formBuild.formRefs().validateField('checkType')
    },
    views(done) {
      done()
    },
    fullScreenChange(val) {
      this.fullScreen = val
    },
    switchOrSubmit() {
      if (this.saveButton) {
        this.buttonLoading = true
        this.submitForm()
      } else {
        this.params.type = 'modify'
        this.saveButton = true
      }
    },
    onChange(val) {
      this.$refs.formBuild.setForm({
        orderNumber: val
      })
    },
    async submitForm() {
      const { form, setForm } = this.$refs.formBuild
      await setForm({
        orderNumber: this.orderNumber
      })
      this.$refs.formBuild.formRefs().validate(async(valid) => {
        if (valid) {
          const items = [...this.selectCheckCheckItem, ...this.selectCheckCheckItemAll]
          const selectCheckCheckItem = this._.cloneDeep(this.selectCheckCheckItem).filter(item => !item.isEdit)
          const selectCheckCheckItemAll = this._.cloneDeep(this.selectCheckCheckItemAll).filter(item => !item.isEdit)
          if (items.length === 0) {
            this.buttonLoading = false
            return this.$message.error('请添加物料')
          }
          if ([0].includes(+this.params.operateType)) {
            const containter = selectCheckCheckItemAll.every(item => item.containerNo &&
              item.containerName &&
              (item.capacity || +item.capacity === 0) &&
              (item.containerQuantity || +item.containerQuantity === 0)
            )
            if (selectCheckCheckItemAll.length > 0 && !containter) {
              this.buttonLoading = false
              return this.$message.error('数量必须大于0,容器编码/容器名称/容量不能为空')
            }
          }
          const count = selectCheckCheckItem.every(item => item.quantity || +item.quantity === 0)
          if (selectCheckCheckItem.length > 0 && !count) {
            this.buttonLoading = false
            return this.$message.error('数量必须大于0')
          }
          if (+this.params.operateType === 5) {
            const warehouseId = selectCheckCheckItem.every(item => item.warehouseId)
            if (!warehouseId) {
              this.buttonLoading = false
              return this.$message.error('请选择仓库')
            }
          }
          if ([4, 5].includes(+this.params.operateType)) {
            const arr = selectCheckCheckItem.filter(item => +item.isAddMaterials === 0 && +item.isSequenceNumber === 1)
            const isTrue = arr.length > 0 ? arr.some(item => ![0, 1].includes(item.quantity))
              : false
            if (isTrue) {
              this.buttonLoading = false
              return this.$message.error('进行序列号管理的物料其数量只能为0或1')
            }
          }
          let res
          if (['add'].indexOf(this.params.type) !== -1) {
            res = await api.addWarehouseOperationSheet(this.createSetData(form))
          }
          if (this.params.type === 'modify') {
            res = await api.modifyWarehouseOperationSheet(this.createResetData(form))
          }
          if (res) {
            this.saveButton = false
            // this.$message.success(this.messages[this.params.type])
            this.returns()
          }
          this.buttonLoading = false
        } else {
          this.buttonLoading = false
        }
      })
    },
    createSetData(raw) {
      let list, inWarehouseName, outInWarehouseTeypeName, warehouseName
      if (+this.params.operateType !== 5) {
        warehouseName = this.allWarehouseList.filter(item => item.id === raw.warehouseId)[0].name
      }
      if ([0, 1].includes(+this.params.operateType)) {
        outInWarehouseTeypeName = this.warehouseTypeList.filter(item => item.id === raw.outInWarehouseTeypeId)[0].name
      }
      let allDatas = [...this.selectCheckCheckItem, ...this.selectCheckCheckItemAll]
      allDatas = allDatas.filter(item => !item.isEdit)
      if (+this.params.operateType === 0) {
        list = allDatas.map(item => {
          return {
            materialsId: item.id,
            materialsName: item.name,
            materialsCode: item.code,
            materialsSpecifications: item.specifications,
            materialsType: item.materialsType,
            materialsUnitId: item.mainUnitId,
            materialsUnitName: item.mainUnitName,
            batchNumber: item.batchNumber, //批次码
            warehouseBatchNumber:item.warehouseBatchNumber, //批次
            sequenceNumber: item.sequenceNumber,
            quantity: item.quantity, // 散件数量
            isContainer: item.isContainer, // 散件0，整件1
            containerQuantity: item.containerQuantity, // 整件数量
            containerNo: item.containerNo,
            containerName: item.containerName,
            capacity: item.capacity,
            stockQuantity: item.currentQuantity,
            warehouseBatchNumber:item.warehouseBatchNumber,
            warehouseLocationIdList: item.warehouseLocationIdList || [],
            warehouseLocationId: item.warehouseLocationIdList && item.warehouseLocationIdList.length > 0 ? this._.last(item.warehouseLocationIdList) : '',
            warehouseLocationName: item.warehouseLocationIdList && item.warehouseLocationIdList.length > 0 ? this.initWarehouseLocationList.filter(items => items.id === +this._.last(item.warehouseLocationIdList))[0].name : ''
          }
        })
      } else {
        if (+this.params.operateType === 2) {
          inWarehouseName = this.allWarehouseList.filter(item => item.id === raw.inWarehouseId)[0].name
        }
        list = allDatas.map(item => {
          let warehouseLocationName = item.warehouseLocationName
          const id = item.warehouseLocationIdList && item.warehouseLocationIdList.length > 0 ? this._.last(item.warehouseLocationIdList) : ''
          if ([4].includes(+this.params.operateType) && item.isAddMaterials === 1) {
            warehouseLocationName = item.warehouseLocationIdList && item.warehouseLocationIdList.length > 0 ? this.initWarehouseLocationList.filter(items => items.id === +this._.last(item.warehouseLocationIdList))[0].name : ''
          }
          if ([5].includes(+this.params.operateType) && item.isAddMaterials === 1) {
            warehouseLocationName = item.warehouseLocationIdList && item.warehouseLocationIdList.length > 0 ? item.initMaterialWarehouseLocationList.filter(items => items.id === +this._.last(item.warehouseLocationIdList))[0].name : ''
          }
          return {
            warehouseOperationDetailsKey: item.key || item.warehouseOperationDetailsKey, // 唯一标识
            warehouseId: [5].includes(+this.params.operateType) ? item.warehouseId : '',
            warehouseName: [5].includes(+this.params.operateType) ? this.allWarehouseList.filter(items => items.id === item.warehouseId)[0].name : '',
            isBatchManager: [4, 5].includes(+this.params.operateType) ? item.isBatchManager : '',
            isSequenceNumber: [4, 5].includes(+this.params.operateType) ? item.isSequenceNumber : '',
            isAddMaterials: [4, 5].includes(+this.params.operateType) ? item.isAddMaterials : '',
            isAddMaterialsLocation: [5].includes(+this.params.operateType) ? item.isAddMaterialsLocation : '',
            materialsId: item.materialsId,
            materialsName: item.materialsName,
            materialsCode: item.materialsCode,
            materialsSpecifications: item.materialsSpecifications,
            materialsType: item.materialsType,
            materialsUnitName: item.materialsUnit,
            batchNumber: item.batchNumber,
            sequenceNumber: item.sequenceNumber,
            quantity: item.quantity,
            isContainer: item.isContainer, // 散件0，整件1
            containerQuantity: item.containerQuantity, // 整件数量
            containerAvailable: item.containerAvailable, // 整件可用数量
            containerSupport: item.containerSupport, // 散件可用数量
            containerNo: item.containerNo,
            containerName: item.containerName,
            capacity: item.capacity,
            stockQuantity: item.currentQuantity,
            warehouseBatchNumber:item.warehouseBatchNumber,

            warehouseLocationId: [4, 5].includes(+this.params.operateType) && item.isAddMaterials === 1 ? id : item.warehouseLocationId,
            warehouseLocationName: warehouseLocationName,
            warehouseLocationIdList: item.warehouseLocationIdList || [],
            inWarehouseLocationId: [4, 5].includes(+this.params.operateType) ? '' : id,
            inWarehouseLocationName: [4, 5].includes(+this.params.operateType) ? '' : item.warehouseLocationIdList && item.warehouseLocationIdList.length > 0 ? this.initWarehouseLocationList.filter(items => items.id === +this._.last(item.warehouseLocationIdList))[0].name : ''
          }
        })
      }
      let trees = [...this.selectMaterialInventorys, ...this.selectMaterialInventorysAll]
      trees = trees.filter(item => !item.isEdit)
      return {
        number: raw.number === '系统生成' ? -1 : raw.number,
        type: this.params.operateType,
        isLocation: [0, 1, 2, 3, 4].includes(+this.params.operateType) ? this.isLocation : '',
        outInWarehouseTeypeId: raw.outInWarehouseTeypeId,
        outInWarehouseTeypeName: outInWarehouseTeypeName,
        orderNumber: this.orderNumber || raw.orderNumber,
        inWarehouseId: raw.inWarehouseId, // 调拨
        inWarehouseName: inWarehouseName,
        warehouseId: raw.warehouseId,
        warehouseName: warehouseName,
        remark: raw.remark,
        warehouseOperationDetailsList: list,
        selectedWarehouseLocationIds: [4].includes(+this.params.operateType) && raw.warehouseLocationIds ? raw.warehouseLocationIds.map(items => this._.last(items)).join('^') : '',
        selectedMaterials: trees.length > 0 ? JSON.stringify(trees) : ''
      }
    },
    createResetData(raw) {
      const obj = {
        id: raw.id,
        versions: raw.versions
      }
      return Object.assign(this.createSetData(raw), obj)
    },
    getSelLabel(outInWarehouseTeypeId) {

    },
    async getWarehouseOperationSheetDetail(id) {
      const res = await api.getWarehouseOperationSheetDetail({ id })
      if (res) {
        this.baseFormData = res
        this.isLocation = res.isLocation
        this.baseFormData.warehouseLocationIds = res.selectedWarehouseLocationIds ? res.selectedWarehouseLocationIds.split('^') : ''
        this.selectMaterialInventorys = res.selectedMaterials ? JSON.parse(res.selectedMaterials).filter(item => +item.isContainer === 0) : []
        this.selectMaterialInventorysAll = res.selectedMaterials ? JSON.parse(res.selectedMaterials).filter(item => +item.isContainer === 1) : []

        if (+this.params.operateType === 0) {
          this.warehousChange(res.warehouseId, true)
          const allDatas = res.warehouseOperationDetailsList.map(item => {
            return {
              id: item.materialsId,
              name: item.materialsName,
              code: item.materialsCode,
              specifications: item.materialsSpecifications,
              materialsType: item.materialsType,
              mainUnitName: item.materialsUnitName,
              warehouseBatchNumber:item.warehouseBatchNumber,
              batchNumber: item.batchNumber,
              isBatchManager: item.batchNumber ? 1 : 0,
              sequenceNumber: item.sequenceNumber,
              isSequenceNumber: item.sequenceNumber ? 1 : 0,
              quantity: item.quantity, // 散件数量
              isContainer: item.isContainer, // 散件0，整件1
              containerQuantity: item.containerQuantity, // 整件数量
              containerNo: item.containerNo,
              containerName: item.containerName,
              capacity: item.capacity,
              containerAvailable: item.containerAvailable, // 整件可用数量
              containerSupport: item.containerSupport, // 散件可用数量
              warehouseLocationIdList: item.warehouseLocationIdList && item.warehouseLocationIdList.length > 0 ? item.warehouseLocationIdList.map(item => Number(item)) : [],
              warehouseLocationId: item.warehouseLocationId,
              warehouseLocationName: item.warehouseLocationName,
              uuid: getUUid()
            }
          })
          this.selectCheckCheckItem = allDatas.filter(item => +item.isContainer !== 1)
          this.selectCheckCheckItemAll = allDatas.filter(item => +item.isContainer === 1)
        } else {
          const datas = res.warehouseOperationDetailsList.filter(item => +item.isContainer !== 1)
          const allData = res.warehouseOperationDetailsList.filter(item => +item.isContainer === 1)

          if (+this.params.operateType === 2) {
            this.inWarehousChange(res.inWarehouseId)
            this.getAllNodesByWarehouseId(res.inWarehouseId)
          } else {
            this.getAllNodesByWarehouseId(res.warehouseId)
          }
          this.selectCheckCheckItem = datas.length > 0 ? datas.map(item => {
            return {
              ...item,
              materialsUnit: item.materialsUnitName,
              currentQuantity: item.stockQuantity,
              warehouseLocationIdList: item.warehouseLocationIdList && item.warehouseLocationIdList.length > 0 ? item.warehouseLocationIdList.map(item => Number(item)) : [],
              materialWarehouseLocationList: item.warehouseList && item.warehouseList.length > 1 ? setTreeData(item.warehouseList) : [],
              initMaterialWarehouseLocationList: item.warehouseList || [],
              uuid: getUUid()
            }
          }) : []
          // 出库整件
          if (+this.params.operateType === 1) {
            this.orderNumber = res.orderNumber
            this.selIsShow = this.warehouseTypeListMap[res.outInWarehouseTeypeId] === '销售发货出库'
            this.baseFormList[1][2].label = this.selIsShow ? '关联销售订单' : '关联单号'
            this.baseFormList[1][2].required = this.selIsShow
            this.$set(this.baseFormList[1][2], 'required', this.selIsShow)
            this.$refs.formBuild.rules.orderNumber = [
              {
                'required': this.selIsShow,
                'message': '请选择关联销售订单',
                'trigger': 'blur'
              }
            ]
            this.$refs.formBuild.setForm({
              orderNumber: res.orderNumber
            })
            this.$refs.formBuild.formRefs().validateField('orderNumber')

            this.selectCheckCheckItemAll = allData.length > 0 ? allData.map(item => {
              return {
                ...item,
                materialsUnit: item.materialsUnitName,
                currentQuantity: item.stockQuantity,
                warehouseLocationIdList: item.warehouseLocationIdList && item.warehouseLocationIdList.length > 0 ? item.warehouseLocationIdList.map(item => Number(item)) : [],
                materialWarehouseLocationList: item.warehouseList && item.warehouseList.length > 1 ? setTreeData(item.warehouseList) : [],
                initMaterialWarehouseLocationList: item.warehouseList || [],
                uuid: getUUid()
              }
            }) : []
          }
        }
      }
    },
    getColumn() {
      if (+this.params.operateType === 0) {
        this.columns.InMaterialColumn = this.InMaterialColumn
        this.getTypes()
      }
      if (+this.params.operateType === 1) {
        this.columns.OutMaterialColumn = this.OutMaterialColumn
        this.getTypes()
      }
      if (+this.params.operateType === 2) {
        this.columns.AllocationMaterialColumn = this.AllocationMaterialColumn
      }
      if (+this.params.operateType === 3) {
        this.columns.TransferMaterialColumn = this.TransferMaterialColumn
      }
      if (+this.params.operateType === 4) {
        this.columns.WarehouseMaterialColumn = this.WarehouseMaterialColumn
      }
      if (+this.params.operateType === 5) {
        this.columns.MaterialColumn = this.MaterialColumn
      }
    },
     // 添加批次码
     async addBatch(data) {
      let paramArr=data.map(item=>{
        return {
          materialsId:item.id,
          materialsCode:item.code
        }
      })
      const res = await bApi.addBatchNumberListBatch(paramArr)
      console.log(res);
      if (res) {
        return res
      }
    },
  }
}
</script>

<style scoped lang="scss">
.inspection-plan-detail-wrappers {
  height: 100%;

  .inspection-plan-detail-header {
    width: 100%;
    padding: 10px 0;
  }
  .order-footer{
    position: absolute;
    bottom:0;
    left:0;
    height:60px;
    width: calc(100% - 22px);
    margin-left: 10px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    z-index: 100;
    background: #fff;
    border-top: 1px solid #dde3eb;
    .footer-le {
      padding-left: 30px;
      height: 20px;
      font-size: 14px;
      font-family: PingFangSC-Regular, PingFang SC;
      font-weight: 400;
      color: #393d60;
      span {
        margin-right: 45px;
        display: inline-block;
      }
    }
    .footer-ri{
      margin-right:30px;
      display:flex;
      justify-content: center;
      align-items: center;
    }
  }
  .inspection-plan-detail-body {
    height: calc(100% - 60px);
    overflow-y: auto;

    .detail-card {
      margin-bottom: 20px;
    }
  }
  ::v-deep {
    .el-col.el-col-6 >.el-form-item {
      min-height: 91px;
    }
    .el-col.el-col-12 > .el-form-item {
      min-height: 91px;
    }
    .date-number {
      width: 100%;
      display: flex;

      .el-form-item:first-child {
        width: 138% !important;

        input {
          border-radius: 4px 0 0 4px;
        }
      }

      .el-form-item:last-child {
        width: 62% !important;

        input {
          border-radius: 0 4px 4px 0;
          border-left: 0;
        }
      }
    }
  }
  .function-header-wrap {
    padding-top: 0;
  }
  ::v-deep {
    .table-row-img {
      > img {
        width: 50px;
      }
    }
  }
}
</style>
