var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "account-detail-wrapper" },
    [
      _c("div", { staticClass: "account-detail-header flex-sbc" }, [
        _c(
          "div",
          {
            staticClass: "left",
            on: {
              click: function($event) {
                return _vm.back()
              }
            }
          },
          [
            _c("img", {
              staticStyle: { width: "8px" },
              attrs: {
                src: require("@/assets/information/procedure/左滑@2x.png")
              }
            }),
            _c(
              "span",
              { staticStyle: { "padding-left": "10px", cursor: "pointer" } },
              [_vm._v("需求详情")]
            )
          ]
        )
      ]),
      _c("div", { staticClass: "all-groups" }, [
        _c(
          "div",
          {
            staticClass: "left",
            style: {
              width: _vm.open ? "calc(100% - 320px)" : "calc(100% - 0px)"
            }
          },
          [
            _c("div", { staticClass: "account-detail-body" }, [
              _c("div", { staticClass: "detail-card" }, [
                _c("div", { staticClass: "card-tit" }, [_vm._v("基本信息")]),
                _c(
                  "div",
                  { staticClass: "card-bot", staticStyle: { padding: "15px" } },
                  [
                    _c(
                      "div",
                      [
                        _c(
                          "el-row",
                          {
                            staticClass: "view-list",
                            staticStyle: {
                              padding: "10px 15px",
                              color: "#393D60"
                            },
                            attrs: { gutter: 24 }
                          },
                          _vm._l(_vm.initColumns, function(item, index) {
                            return _c(
                              "el-col",
                              {
                                key: item.prop,
                                style: {
                                  marginBottom: index !== 4 ? "20px" : 0
                                },
                                attrs: { span: 6 }
                              },
                              [
                                _c(
                                  "div",
                                  { staticStyle: { color: "#9597AE" } },
                                  [_vm._v(_vm._s(item.label))]
                                ),
                                _c("div", { staticClass: "bot-tis" }, [
                                  _vm._v(_vm._s(_vm.getValue(item) || "-"))
                                ])
                              ]
                            )
                          }),
                          1
                        )
                      ],
                      1
                    )
                  ]
                )
              ])
            ]),
            _c(
              "div",
              { staticStyle: { "margin-top": "15px" } },
              [
                _c(
                  "el-tabs",
                  {
                    staticClass: "detail-tabs",
                    attrs: { type: "border-card", "tab-position": "top" },
                    on: { "tab-click": _vm.tabClick },
                    model: {
                      value: _vm.params.radio,
                      callback: function($$v) {
                        _vm.$set(_vm.params, "radio", $$v)
                      },
                      expression: "params.radio"
                    }
                  },
                  [
                    _c(
                      "el-tab-pane",
                      { attrs: { label: "关联工单", name: "quanityRadio" } },
                      [
                        _c("RelationWork", {
                          attrs: {
                            type: _vm.baseFormData.content,
                            "demand-id": _vm.baseFormData.id,
                            "demand-code": _vm.baseFormData.code,
                            status: _vm.baseFormData.status,
                            "is-all": _vm.isAll,
                            infos: _vm.baseFormData
                          }
                        })
                      ],
                      1
                    ),
                    _c(
                      "el-tab-pane",
                      { attrs: { label: "关联设备", name: "technialRadio" } },
                      [
                        _c("RelationEquipment", {
                          attrs: {
                            list: _vm.baseFormData.facilityMappingList,
                            type: "detail"
                          }
                        })
                      ],
                      1
                    ),
                    _c(
                      "el-tab-pane",
                      { attrs: { label: "关联工具", name: "relationTool" } },
                      [
                        _vm.params.radio === "relationTool"
                          ? _c("MTable", {
                              ref: "mTable",
                              attrs: {
                                height: _vm.height,
                                columns: _vm.toolColumns,
                                data: _vm.toolDatas,
                                showPage: false
                              },
                              scopedSlots: _vm._u(
                                [
                                  {
                                    key: "index",
                                    fn: function(ref) {
                                      var $index = ref.$index
                                      return _c("div", {}, [
                                        _vm._v(_vm._s($index + 1))
                                      ])
                                    }
                                  },
                                  {
                                    key: "code",
                                    fn: function(ref) {
                                      var row = ref.row
                                      return _c(
                                        "div",
                                        {},
                                        [
                                          _c(
                                            "el-button",
                                            {
                                              attrs: { type: "text" },
                                              on: {
                                                click: function($event) {
                                                  return _vm.toToolDetail(row)
                                                }
                                              }
                                            },
                                            [_vm._v(_vm._s(row.code))]
                                          )
                                        ],
                                        1
                                      )
                                    }
                                  }
                                ],
                                null,
                                false,
                                456158281
                              )
                            })
                          : _vm._e()
                      ],
                      1
                    ),
                    _c(
                      "el-tab-pane",
                      { attrs: { label: "其他文件", name: "fileRadio" } },
                      [
                        _c("MTable", {
                          ref: "mTable2",
                          attrs: {
                            height: _vm.heights,
                            "show-page": false,
                            columns: _vm.fileColumns,
                            data: _vm.drawingsList,
                            "columns-setting": false
                          },
                          scopedSlots: _vm._u([
                            {
                              key: "index",
                              fn: function(ref) {
                                var $index = ref.$index
                                return _c("div", {}, [
                                  _vm._v(_vm._s($index + 1))
                                ])
                              }
                            },
                            {
                              key: "action",
                              fn: function(ref) {
                                var row = ref.row
                                var $index = ref.$index
                                return _c(
                                  "div",
                                  {},
                                  [
                                    row.type === 0 || row.type === 2
                                      ? _c(
                                          "el-button",
                                          {
                                            attrs: { type: "text" },
                                            on: {
                                              click: function($event) {
                                                return _vm.viewInfos(
                                                  row,
                                                  $index
                                                )
                                              }
                                            }
                                          },
                                          [_vm._v("查看 ")]
                                        )
                                      : _vm._e(),
                                    row.type === 1
                                      ? _c(
                                          "el-button",
                                          {
                                            attrs: { type: "text" },
                                            on: {
                                              click: function($event) {
                                                return _vm.downInfos(
                                                  row,
                                                  $index
                                                )
                                              }
                                            }
                                          },
                                          [_vm._v("下载")]
                                        )
                                      : _vm._e(),
                                    row.type === 3
                                      ? _c(
                                          "el-button",
                                          {
                                            attrs: { type: "text" },
                                            on: {
                                              click: function($event) {
                                                return _vm.downInfos2(row)
                                              }
                                            }
                                          },
                                          [_vm._v("下载")]
                                        )
                                      : _vm._e(),
                                    row.type === 2 &&
                                    row.isDeleted === 1 &&
                                    _vm.isAll === 0
                                      ? _c(
                                          "el-button",
                                          {
                                            attrs: { type: "text" },
                                            on: {
                                              click: function($event) {
                                                return _vm.modifys(row, $index)
                                              }
                                            }
                                          },
                                          [_vm._v("修改 ")]
                                        )
                                      : _vm._e(),
                                    (row.type === 1 || row.type === 2) &&
                                    row.isDeleted === 1 &&
                                    _vm.isAll === 0
                                      ? _c(
                                          "el-button",
                                          {
                                            attrs: { type: "text" },
                                            on: {
                                              click: function($event) {
                                                return _vm.delInfos(row, $index)
                                              }
                                            }
                                          },
                                          [_vm._v("删除 ")]
                                        )
                                      : _vm._e()
                                  ],
                                  1
                                )
                              }
                            }
                          ])
                        })
                      ],
                      1
                    )
                  ],
                  1
                )
              ],
              1
            )
          ]
        ),
        _c(
          "div",
          { staticClass: "hot", style: { width: _vm.open ? "320px" : "15px" } },
          [
            _vm._m(0),
            _c(
              "div",
              { staticClass: "content" },
              [
                _c(
                  "el-timeline",
                  { attrs: { reverse: _vm.reverse } },
                  _vm._l(_vm.records, function(item, index) {
                    return _c(
                      "el-timeline-item",
                      {
                        key: index,
                        attrs: {
                          timestamp: item.createTime,
                          color:
                            index === _vm.records.length - 1 ? "#607FFF" : "",
                          placement: "top"
                        }
                      },
                      [
                        [
                          _c("div", [_vm._v(_vm._s(item.content))]),
                          item.operateType === 1 && item.updateRemark
                            ? _c("div", [
                                _vm._v(
                                  " 派工说明：" +
                                    _vm._s(item.updateRemark) +
                                    " "
                                )
                              ])
                            : _vm._e(),
                          item.operateType === 5 && item.updateRemark
                            ? _c("div", [
                                _vm._v(
                                  " 修改意见：" +
                                    _vm._s(item.updateRemark) +
                                    " "
                                )
                              ])
                            : _vm._e(),
                          item.operateType === 7 && item.updateRemark
                            ? _c("div", [
                                _vm._v(
                                  " 驳回说明：" +
                                    _vm._s(item.updateRemark) +
                                    " "
                                )
                              ])
                            : _vm._e(),
                          item.operateType === 13 && item.updateRemark
                            ? _c("div", [
                                _vm._v(
                                  " 审批意见：" +
                                    _vm._s(item.updateRemark) +
                                    " "
                                )
                              ])
                            : _vm._e(),
                          item.operateType === 3 && item.updateRemark
                            ? _c("div", [
                                _vm._v(
                                  " 退回说明：" +
                                    _vm._s(item.updateRemark) +
                                    " "
                                )
                              ])
                            : _vm._e()
                        ]
                      ],
                      2
                    )
                  }),
                  1
                )
              ],
              1
            ),
            _c("img", {
              staticClass: "open-img",
              staticStyle: { width: "19px", display: "block" },
              attrs: { src: _vm.open ? _vm.closeImg : _vm.openImg, alt: "" },
              on: {
                click: function($event) {
                  _vm.open = !_vm.open
                }
              }
            })
          ]
        )
      ]),
      _vm.baseFormData.content !== 3 && _vm.baseFormData.status === 6
        ? _c("div", { staticStyle: { height: "50px" } })
        : _vm._e(),
      _vm.baseFormData.content !== 3 &&
      _vm.baseFormData.status === 6 &&
      _vm.isAll === 0
        ? _c("div", { staticClass: "detail-footer" }, [
            _c(
              "div",
              { staticClass: "footer-ri" },
              [
                _c(
                  "el-button",
                  {
                    attrs: { type: "danger", size: "small" },
                    on: { click: _vm.rejectApproval }
                  },
                  [_vm._v("驳回")]
                ),
                _c(
                  "el-button",
                  {
                    attrs: {
                      type: "primary",
                      loading: _vm.buttonLoading,
                      size: "small"
                    },
                    on: {
                      click: function($event) {
                        return _vm.passApproval(1)
                      }
                    }
                  },
                  [_vm._v("确认关闭")]
                )
              ],
              1
            )
          ])
        : _vm._e(),
      _c(
        "MDialog",
        {
          attrs: { "append-to-body": true, title: "驳回", width: "500px" },
          on: { onOk: _vm.submitFormReject },
          model: {
            value: _vm.dialogVisible,
            callback: function($$v) {
              _vm.dialogVisible = $$v
            },
            expression: "dialogVisible"
          }
        },
        [
          _c("MFormBuilder", {
            ref: "modifyForm",
            attrs: {
              "form-list": _vm.modifyFormList,
              "label-position": "right"
            }
          })
        ],
        1
      ),
      _c(
        "MDialog",
        {
          staticClass: "extra-froms",
          attrs: {
            "append-to-body": true,
            title: _vm.titles ? "修改" : "完成",
            width: "500px"
          },
          on: { onOk: _vm.submitFormFinish },
          model: {
            value: _vm.finshVisible,
            callback: function($$v) {
              _vm.finshVisible = $$v
            },
            expression: "finshVisible"
          }
        },
        [
          _c("MFormBuilder", {
            ref: "finshForm",
            attrs: {
              "form-list": _vm.finshFormList,
              "form-data": _vm.finshFormData,
              "label-position": "right"
            }
          })
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = [
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "top" }, [
      _c("div", { staticClass: "title" }, [_vm._v("过程记录")]),
      _c("i")
    ])
  }
]
render._withStripped = true

export { render, staticRenderFns }