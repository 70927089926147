<template>
  <div class="account-detail-wrapper">
    <div class="account-detail-header flex-sbc">
      <div class="left" @click="back()">
        <img src="@/assets/information/procedure/左滑@2x.png" style="width: 8px">
        <span style="padding-left: 10px;cursor: pointer">需求详情</span>
      </div>
    </div>
    <div class="all-groups">
      <div class="left" :style="{ width: open ? 'calc(100% - 320px)' : 'calc(100% - 0px)' }">
        <div class="account-detail-body">
          <div class="detail-card">
            <div class="card-tit">基本信息</div>
            <div class="card-bot" style="padding: 15px">
              <div>
                <el-row class="view-list" style="padding: 10px 15px;color: #393D60;" :gutter="24">
                  <!--            暂时这么处理距离问题-->
                  <el-col
                    v-for="(item, index) in initColumns"
                    :key="item.prop"
                    :span="6"
                    :style="{marginBottom: index !== 4 ? '20px' : 0 }"
                  >
                    <div style="color: #9597AE;">{{ item.label }}</div>
                    <div class="bot-tis">{{ getValue(item) || '-' }}</div>
                  </el-col>
                </el-row>
              </div>
            </div>
          </div>
        </div>
        <div style="margin-top: 15px;">
          <el-tabs v-model="params.radio" class="detail-tabs" type="border-card" tab-position="top"
                   @tab-click="tabClick">
            <el-tab-pane label="关联工单" name="quanityRadio">
              <RelationWork :type="baseFormData.content" :demand-id="baseFormData.id" :demand-code="baseFormData.code"
                            :status="baseFormData.status" :is-all="isAll" :infos="baseFormData"/>
            </el-tab-pane>
            <el-tab-pane label="关联设备" name="technialRadio">
              <RelationEquipment :list="baseFormData.facilityMappingList" :type="'detail'"/>
            </el-tab-pane>
            <el-tab-pane label="关联工具" name="relationTool">
              <MTable
                v-if="params.radio === 'relationTool'"
                ref="mTable"
                :height="height"
                :columns="toolColumns"
                :data="toolDatas"
                :showPage="false"
              >
                <div slot="index" slot-scope="{ $index }">{{ $index + 1 }}</div>
                <div slot="code" slot-scope="{ row }">
                  <el-button type="text" @click="toToolDetail(row)">{{ row.code }}</el-button>
                </div>
              </MTable>
            </el-tab-pane>
            <!-- <el-tab-pane label="装机记录" name="zhRadio" v-if="this.baseFormData.content === 2">
              <MTable ref="mTable2" :height="heights" :show-page="false" :columns="assembleColumns" :data="baseFormData.assembleList" :columns-setting="false">
                <div slot="index" slot-scope="{ row, $index }">{{ $index + 1 }}</div>
                <div slot="updateTime" slot-scope="{ row }">{{row.updateTime || '-'}}</div>
                <div slot="remark" slot-scope="{ row }">{{row.remark || '-'}}</div>
                <div slot="updaterName" slot-scope="{ row }">{{row.updaterName || '-'}}</div>
                <div slot="action" slot-scope="{ row, $index }" v-if="isAll === 0">
                  <el-button type="text" @click="modifyInfos(row, 1)" v-if="row.result === 1">完成</el-button>
                  <el-button type="text" @click="modifyInfos(row, 2)" v-if="row.result === 2">修改</el-button>
                  <div v-if="row.result === 0">-</div>
                </div>
              </MTable>
            </el-tab-pane> -->
            <el-tab-pane label="其他文件" name="fileRadio">
              <MTable ref="mTable2" :height="heights" :show-page="false" :columns="fileColumns" :data="drawingsList"
                      :columns-setting="false">
                <div slot="index" slot-scope="{ $index }">{{ $index + 1 }}</div>
                <div slot="action" slot-scope="{ row, $index }">
                  <el-button v-if="row.type === 0 || row.type === 2" type="text" @click="viewInfos(row, $index)">查看
                  </el-button>
                  <el-button v-if="row.type === 1" type="text" @click="downInfos(row, $index)">下载</el-button>
                  <el-button v-if="row.type === 3" type="text" @click="downInfos2(row)">下载</el-button>
                  <el-button v-if="row.type === 2 && row.isDeleted === 1 && isAll === 0" type="text"
                             @click="modifys(row, $index)">修改
                  </el-button>
                  <el-button v-if="(row.type === 1 || row.type === 2 ) && row.isDeleted === 1 && isAll === 0"
                             type="text" @click="delInfos(row, $index)">删除
                  </el-button>
                </div>
              </MTable>
            </el-tab-pane>
          </el-tabs>
        </div>
      </div>
      <div class="hot" :style="{ width: open ? '320px' : '15px'}">
        <div class="top">
          <div class="title">过程记录</div>
          <i/>
        </div>
        <div class="content">
          <el-timeline :reverse="reverse">
            <el-timeline-item
              v-for="(item, index) in records"
              :key="index"
              :timestamp="item.createTime"
              :color="index === (records.length-1) ? '#607FFF': ''"
              placement="top"
            >
              <template>
                <div>{{ item.content }}</div>
                <div v-if="item.operateType === 1 && item.updateRemark">
                  派工说明：{{ item.updateRemark }}
                </div>
                <div v-if="item.operateType === 5 && item.updateRemark">
                  修改意见：{{ item.updateRemark }}
                </div>
                <div v-if="item.operateType === 7 && item.updateRemark">
                  驳回说明：{{ item.updateRemark }}
                </div>
                <div v-if="item.operateType === 13 && item.updateRemark">
                  审批意见：{{ item.updateRemark }}
                </div>
                <div v-if="item.operateType === 3 && item.updateRemark">
                  退回说明：{{ item.updateRemark }}
                </div>
              </template>

            </el-timeline-item>
          </el-timeline>
        </div>
        <img :src="open ? closeImg : openImg" class="open-img" alt="" style="width: 19px;display: block"
             @click="open = !open">
      </div>
    </div>

    <div v-if="baseFormData.content !== 3 && baseFormData.status === 6" style="height: 50px"/>
    <div v-if="baseFormData.content !== 3 && baseFormData.status === 6 && isAll === 0" class="detail-footer">
      <div class="footer-ri">
        <el-button type="danger" size="small" @click="rejectApproval">驳回</el-button>
        <el-button type="primary" :loading="buttonLoading" size="small" @click="passApproval(1)">确认关闭</el-button>
      </div>
    </div>
    <MDialog v-model="dialogVisible" :append-to-body="true" title="驳回" width="500px" @onOk="submitFormReject">
      <MFormBuilder ref="modifyForm" :form-list="modifyFormList" label-position="right"/>
    </MDialog>
    <MDialog v-model="finshVisible" :append-to-body="true" :title="titles ? '修改' : '完成'" width="500px"
             class="extra-froms" @onOk="submitFormFinish">
      <MFormBuilder ref="finshForm" :form-list="finshFormList" :form-data="finshFormData" label-position="right"/>
    </MDialog>
  </div>
</template>

<script>
import { demandServiceType, demandServiceTypes } from '@/config/options.sales'
import RelationEquipment from '@/views/service/demand-center/components/relation-equipment'
import RelationWork from '@/views/service/demand-center/components/relation-work'
import api from '@/api/xiewei/service/demand-center'
import { mulColumns, columns, fileColumns, assembleColumns, toolColumns } from './columns'
import { getUUid } from '@/utils'
import { modifyFormList, finshFormList } from './form-list'
import openImg from '@/assets/information/open.png'
import closeImg from '@/assets/information/close.png'
import saveAs from 'file-saver'
import templateUrls, {templateName} from "@/components/BatchImport/templateUrls";

export default {
  name: 'DemandCenterDetail',
  components: { RelationWork, RelationEquipment },
  data() {
    return {
      demandServiceTypes,
      demandServiceType,
      mulColumns,
      toolColumns,
      toolDatas: [],
      initColumns: columns,
      fileColumns,
      assembleColumns,
      modifyFormList,
      finshFormList,
      openImg,
      closeImg,
      open: false,
      reverse: true,
      drawingsList: [],
      params: {
        radio: 'quanityRadio'
      },
      baseFormData: {
        id: undefined,
        facilityMappingList: [],
        assembleList: []
      },
      buttonLoading: false,
      dialogVisible: false,
      records: [],
      titles: false,
      finshVisible: false,
      finshFormData: {},
      isAll: 0
    }
  },
  computed: {
    tableDialogHeight() {
      return this.$store.state.app.contentHeight - 54 - 100 - 50 - 60 - 70
    },
    heights() {
      return this.$store.state.app.contentHeight - 256 - 220
    }
  },
  mounted() {
    this.isAll = +this.$route.query.isAll || 0
    this.getFacilityDemandDetail()
  },
  methods: {
    toToolDetail(row) {
      consoleSelf.info('luyouxin', row)
      this.$router.push({
        name: 'ToolLedgerDetail',
        params: {
          id: row.toolId
        }
      })
    },
    async delInfos(row) {
      if (row.type === 2) {
        const res = await api.delDemandApplication({ id: row.id })
        if (res) {
          this.$message.success('删除成功')
          this.getFacilityDemandDetail()
        }
      } else {
        const res = await api.delDemandAttachments({ id: row.id })
        if (res) {
          this.$message.success('删除成功')
          this.getFacilityDemandDetail()
        }
      }
    },
    async submitFormFinish(callback) {
      const { form } = this.$refs.finshForm
      this.$refs.finshForm.formRefs().validate(async(valid) => {
        if (valid) {
          const res = await api.modifyFacilityDemandAssemble(Object.assign({},
            {
              ...form,
              id: this.finshFormData.id,
              step: this.finshFormData.step,
              facilityDemandId: this.$route.query.id,
              result: 2
            }))
          if (res) {
            this.getFacilityDemandDetail()
            this.finshVisible = false
          }
          callback(true)
        } else {
          callback()
        }
      })
    },
    modifyInfos(row, val) {
      this.titles = val === 2
      this.finshFormData = this._.cloneDeep(row)
      this.finshVisible = true
    },
    viewInfos(row) {
      if (row.type === 0) {
        this.$router.push({
          name: 'addTravelPlan',
          query: { planId: row.id, type: 'scan' }
        })
      }
      if (row.type === 2) {
        this.$router.push({
          name: 'addLocalDevelopment',
          query: { id: row.id, type: 'view' }
        })
      }
    },
    modifys(row) {
      this.$router.push({
        name: 'addLocalDevelopment',
        query: { id: row.id, type: 'modify' }
      })
    },
    downInfos(row) {
      saveAs(row.ossAddress, row.fileName)
    },
    downInfos2(row) {
      const url = row.ossAddress
      const name = row.fileName
      this.downloadFile(url, name)
    },
    downloadFile(urlP, name) {
      fetch(urlP)
        .then(response => response.blob())
        .then(blob => {
          const link = document.createElement('a')
          link.style.display = 'none'
          link.href = URL.createObjectURL(blob)
          link.download = name
          document.body.appendChild(link)
          link.click()
          document.body.removeChild(link)
        })
    },
    async getFacilityDemandDetail() {
      const res = await api.getFacilityDemandDetail(this.$route.query.id)
      if (res) {
        this.toolDatas = res.toolMappingList || []
        this.initColumns = res.content === 5 ? this.mulColumns : res.content === 4
          ? this.mulColumns.filter(item => item.prop !== 'planLevel') : columns
        this.baseFormData = {
          ...res,
          planDate: `${res.planStartTime} ~ ${res.planEndTime}`
        }
        this.initColumns[1].form.options = res.content === 4 || res.content === 5 ? this.demandServiceTypes : this.demandServiceType
        this.drawingsList = res.fileInfoList ? res.fileInfoList.map(item => {
          return {
            ...item,
            typeName: item.type === 0 ? '出差计划' : item.type === 1 ? '技术方案' : item.type === 2 ? '本地开发申请表' : '其他',
            resultName: item.result || '-'
          }
        }) : []
        this.baseFormData.facilityMappingList = res.facilityMappingList && res.facilityMappingList.length > 0
          ? res.facilityMappingList.map(item => {
            return {
              ...item,
              code: item.facilityCode,
              name: item.facilityName
            }
          }) : []
        this.records = res.timelineResultBeanList || []

        if (['bbelc'].includes(sessionStorage.getItem('memberCode'))) {
          const had = this.initColumns.filter(p => p.prop === 'happenTime')
          if (!had.length) {
            this.initColumns.splice(7, 0, {
              prop: 'happenTime',
              label: '发生时间',
              sortable: false,
              hiddenSearch: true
            })
          }
        }
      }
    },
    getValue(row) {
      const result = this.baseFormData[row.prop]
      if (row.form && row.form.options && row.form.options.length > 0) {
        const current = row.form.options.find(item => item.id === result)
        return current && current.name
      }
      return result
    },
    back() {
      this.$router.push({ name: 'demandCenterList' })
    },
    tabClick() {
    },
    rejectApproval() {
      this.dialogVisible = true
    },
    async passApproval(val, reason) {
      const res = await api.modifyFacilityDemandOperate({
        sign: 4,
        demandId: this.baseFormData.id,
        status: val,
        updateRemark: reason || '',
        content: +this.baseFormData.content === 3 ? 3 : 0
      })
      if (res) {
        this.back()
      }
    },
    async submitFormReject(callback) {
      const { form } = this.$refs.modifyForm
      this.$refs.modifyForm.formRefs().validate(async(valid) => {
        if (valid) {
          this.passApproval(0, form.updateRemark)
          callback(true)
        } else {
          callback()
        }
      })
    }
  }
}
</script>

<style scoped lang="scss">
.extra-froms ::v-deep {
  .el-form-item {
    margin-bottom: 18px !important;
  }
}

.account-detail-wrapper {
  .hot {
    //border: 1px solid #BDC1CA;
    background: #ffffff;
    border-radius: 4px;
    width: 320px;
    position: absolute;
    top: 12px;
    right: -1px;
    bottom: 26px;

    .title {
      height: 30px;
      font-size: 16px;
      font-family: PingFangSC-Regular, PingFang SC;
      font-weight: 400;
      color: #000;
      margin: 10px;
      border-bottom: 1px solid #ccc;
    }

    .open-img {
      position: absolute;
      left: -9px;
      top: 50%;
      transform: translateX(-50%);
      z-index: 100;
      cursor: pointer;
    }

    .content {
      overflow-y: auto;
      height: calc(100% - 100px);
      margin-top: 30px;
    }
  }

  .account-detail-header {
    width: 100%;
    padding: 10px 0;
  }

  .account-detail-body {
    padding-top: 10px;

    .card-bot {
      .info {
        padding: 20px;
        border-bottom: 1px solid #DDE3EB;
      }

      // ::v-deep .el-tabs__nav {
      //   position: relative;
      //   left: 50%;
      //   transform: translateX(-50%) !important;
      // }
    }
  }
}

.faultStatus {
  font-size: 12px;
  font-family: PingFangSC-Regular, PingFang SC;
  font-weight: 400;
  height: 22px;
  line-height: 22px;
  border-radius: 4px;
  width: 50px;
  text-align: center;
}

.drawing-img {
  width: 50px;
  height: 48px;
  display: block;
  cursor: pointer;
  color: #fff;
}

.result-wrapper {
  > div {
    display: flex;
    font-size: 16px;
    padding: 10px 0;

    span {
      &:first-child {
        width: 80px;
        text-align-last: justify;
        text-align: justify;
      }
    }
  }
}

::v-deep {
  .table-row-img {
    > img {
      height: 50px;
    }
  }
}

.all-select {
  display: flex;
  justify-content: flex-start;
  align-items: center;

  .checkAll {
    margin-right: 20px;
  }

  .all-li {
    width: 80px;
    height: 26px;
    background: #FFFFFF;
    border-radius: 16px;
    border: 1px solid #DDE3EB;
    margin-right: 10px;
    font-size: 14px;
    font-family: PingFangSC-Regular, PingFang SC;
    font-weight: 400;
    color: #A4B3C6;
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;
  }

  .active {
    color: #607FFF;
    border-color: #607FFF;
    background: #EFF2FF;
  }
}

.detail-footer {
  position: absolute;
  bottom: 0;
  left: 0;
  height: 60px;
  width: calc(100% - 22px);
  margin-left: 10px;
  display: flex;
  justify-content: flex-end;
  align-items: center;
  z-index: 100;
  background: #fff;
  border-top: 1px solid #dde3eb;

  .footer-le {
    padding-left: 30px;
    height: 20px;
    font-size: 14px;
    font-family: PingFangSC-Regular, PingFang SC;
    font-weight: 400;
    color: #393d60;

    span {
      margin-right: 45px;
      display: inline-block;
    }
  }

  .footer-ri {
    margin-right: 30px;
    display: flex;
    justify-content: center;
    align-items: center;
  }
}
</style>
