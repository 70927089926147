var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "inspection-plan-detail-wrappers" },
    [
      _c("div", { staticClass: "inspection-plan-detail-header flex-sbc" }, [
        _c(
          "div",
          {
            staticClass: "left",
            on: {
              click: function($event) {
                return _vm.$router.back()
              }
            }
          },
          [
            _c("img", {
              staticStyle: { width: "8px" },
              attrs: {
                src: require("@/assets/information/procedure/左滑@2x.png")
              }
            }),
            _c(
              "span",
              { staticStyle: { "padding-left": "10px", cursor: "pointer" } },
              [
                _vm._v(
                  _vm._s(
                    _vm.messages[_vm.params.type + "O"] +
                      _vm.typeList[_vm.params.operateType]
                  )
                )
              ]
            )
          ]
        )
      ]),
      _c("div", { staticClass: "inspection-plan-detail-body" }, [
        _c("div", { staticClass: "detail-card" }, [
          _c("div", { staticClass: "card-tit" }, [_vm._v("基本信息")]),
          _c(
            "div",
            { staticClass: "card-bot" },
            [
              _c(
                "MFormBuilder",
                {
                  ref: "formBuild",
                  attrs: {
                    "form-list": _vm.baseFormList[_vm.params.operateType],
                    "form-data": _vm.baseFormData
                  }
                },
                [
                  _c(
                    "div",
                    { attrs: { slot: "saveBtn" }, slot: "saveBtn" },
                    [
                      _c(
                        "el-button",
                        {
                          staticStyle: { "margin-top": "42px" },
                          attrs: {
                            size: "small",
                            type: "primary",
                            disabled: _vm.isTrue
                          },
                          on: { click: _vm.saveItem }
                        },
                        [_vm._v("确认")]
                      )
                    ],
                    1
                  ),
                  _c(
                    "div",
                    { attrs: { slot: "orderNumber" }, slot: "orderNumber" },
                    [
                      !_vm.selIsShow
                        ? _c("el-input", {
                            attrs: { placeholder: "请输入关联单号" },
                            on: { change: _vm.onChange },
                            model: {
                              value: _vm.orderNumber,
                              callback: function($$v) {
                                _vm.orderNumber = $$v
                              },
                              expression: "orderNumber"
                            }
                          })
                        : _c(
                            "el-select",
                            {
                              staticStyle: { width: "100%" },
                              attrs: {
                                placeholder: "请选择关联销售订单",
                                filterable: ""
                              },
                              on: { change: _vm.onChange },
                              model: {
                                value: _vm.orderNumber,
                                callback: function($$v) {
                                  _vm.orderNumber = $$v
                                },
                                expression: "orderNumber"
                              }
                            },
                            _vm._l(_vm.orderNumberOptions, function(item) {
                              return _c("el-option", {
                                key: item.code,
                                attrs: { label: item.code, value: item.code }
                              })
                            }),
                            1
                          )
                    ],
                    1
                  )
                ]
              )
            ],
            1
          )
        ]),
        _c("div", { staticClass: "detail-card" }, [
          _c("div", { staticClass: "card-tit" }, [
            _vm._v(
              _vm._s([0, 1].includes(+_vm.params.operateType) ? "散件" : "物料")
            )
          ]),
          _c(
            "div",
            { staticClass: "card-bot" },
            [
              ![4].includes(+_vm.params.operateType)
                ? _c(
                    "el-button",
                    {
                      staticStyle: { "margin-bottom": "10px" },
                      attrs: { size: "small", type: "primary" },
                      on: { click: _vm.addCheckItem }
                    },
                    [
                      _vm._v(
                        _vm._s(
                          [0].includes(+_vm.params.operateType)
                            ? "添加物料"
                            : "选择物料"
                        )
                      )
                    ]
                  )
                : _vm._e(),
              [4, 5].includes(+_vm.params.operateType)
                ? _c(
                    "el-button",
                    {
                      staticStyle: { "margin-bottom": "10px" },
                      attrs: { size: "small", type: "primary" },
                      on: {
                        click: function($event) {
                          return _vm.addCheckItem(1)
                        }
                      }
                    },
                    [_vm._v("添加物料")]
                  )
                : _vm._e(),
              _c("MTable", {
                ref: "pointCheckTable",
                attrs: {
                  height: 300,
                  columns: _vm.columns[_vm.coulmnType[_vm.params.operateType]],
                  "columns-setting": false,
                  "show-page": false,
                  data: _vm.selectCheckCheckItem,
                  "only-key": "uuid"
                },
                scopedSlots: _vm._u([
                  {
                    key: "index",
                    fn: function(ref) {
                      var $index = ref.$index
                      return _c("div", {}, [_vm._v(_vm._s($index + 1))])
                    }
                  },
                  {
                    key: "quantity",
                    fn: function(ref) {
                      var row = ref.row
                      return _c(
                        "div",
                        {},
                        [
                          [4, 5].includes(+_vm.params.operateType)
                            ? [
                                +row.isSequenceNumber === 1 &&
                                row.isAddMaterials === 1
                                  ? _c("div", [_vm._v(_vm._s(row.quantity))])
                                  : _c(
                                      "div",
                                      {
                                        staticStyle: {
                                          display: "flex",
                                          "align-items": "center",
                                          "justify-content": "flex-start"
                                        }
                                      },
                                      [
                                        _c(
                                          "span",
                                          {
                                            staticStyle: {
                                              color: "#f00",
                                              width: "10px"
                                            }
                                          },
                                          [_vm._v("*")]
                                        ),
                                        _c("el-input-number", {
                                          staticStyle: { width: "100%" },
                                          attrs: {
                                            min: 0,
                                            "controls-position": "right",
                                            step: 1
                                          },
                                          on: {
                                            blur: function($event) {
                                              row.quantity = _vm.sliceVal(
                                                row.quantity,
                                                5
                                              )
                                            }
                                          },
                                          model: {
                                            value: row.quantity,
                                            callback: function($$v) {
                                              _vm.$set(row, "quantity", $$v)
                                            },
                                            expression: "row.quantity"
                                          }
                                        })
                                      ],
                                      1
                                    )
                              ]
                            : [
                                +row.isSequenceNumber === 1
                                  ? _c("div", [_vm._v(_vm._s(row.quantity))])
                                  : _c(
                                      "div",
                                      {
                                        staticStyle: {
                                          display: "flex",
                                          "align-items": "center",
                                          "justify-content": "flex-start"
                                        }
                                      },
                                      [
                                        _c(
                                          "span",
                                          {
                                            staticStyle: {
                                              color: "#f00",
                                              width: "10px"
                                            }
                                          },
                                          [_vm._v("*")]
                                        ),
                                        _c("el-input-number", {
                                          staticStyle: { width: "100%" },
                                          attrs: {
                                            disabled:
                                              row.isEdit &&
                                              [1, 2, 3].includes(
                                                +_vm.params.operateType
                                              ),
                                            min: 0,
                                            max: [0, 2, 3, 4, 5].includes(
                                              +_vm.params.operateType
                                            )
                                              ? undefined
                                              : +row.currentQuantity,
                                            "controls-position": "right",
                                            step: 1
                                          },
                                          on: {
                                            blur: function($event) {
                                              row.quantity = _vm.sliceVal(
                                                row.quantity,
                                                5
                                              )
                                            }
                                          },
                                          model: {
                                            value: row.quantity,
                                            callback: function($$v) {
                                              _vm.$set(row, "quantity", $$v)
                                            },
                                            expression: "row.quantity"
                                          }
                                        })
                                      ],
                                      1
                                    )
                              ]
                        ],
                        2
                      )
                    }
                  },
                  {
                    key: "specifications",
                    fn: function(ref) {
                      var row = ref.row
                      return _c("div", {}, [_vm._v(_vm._s(row.specifications))])
                    }
                  },
                  {
                    key: "warehouseBatchNumber",
                    fn: function(ref) {
                      var row = ref.row
                      return _c(
                        "div",
                        {},
                        [
                          row.isAddMaterials === 0
                            ? _c("span", [
                                _vm._v(_vm._s(row.warehouseBatchNumber || "-"))
                              ])
                            : [
                                +row.isBatchManager === 1
                                  ? _c(
                                      "div",
                                      {
                                        staticStyle: {
                                          display: "flex",
                                          "align-items": "center",
                                          "justify-content": "flex-start"
                                        }
                                      },
                                      [
                                        _c("el-input", {
                                          model: {
                                            value: row.warehouseBatchNumber,
                                            callback: function($$v) {
                                              _vm.$set(
                                                row,
                                                "warehouseBatchNumber",
                                                $$v
                                              )
                                            },
                                            expression:
                                              "row.warehouseBatchNumber"
                                          }
                                        })
                                      ],
                                      1
                                    )
                                  : _c("span", [_vm._v("-")])
                              ]
                        ],
                        2
                      )
                    }
                  },
                  {
                    key: "batchNumber",
                    fn: function(ref) {
                      var row = ref.row
                      return _c(
                        "div",
                        {},
                        [
                          row.isAddMaterials === 0
                            ? _c("span", [
                                _vm._v(_vm._s(row.batchNumber || "-"))
                              ])
                            : [
                                +row.isBatchManager === 1
                                  ? _c(
                                      "div",
                                      {
                                        staticStyle: {
                                          display: "flex",
                                          "align-items": "center",
                                          "justify-content": "flex-start"
                                        }
                                      },
                                      [
                                        _c(
                                          "span",
                                          {
                                            staticStyle: {
                                              color: "#f00",
                                              width: "10px"
                                            }
                                          },
                                          [_vm._v("*")]
                                        ),
                                        _c("el-input", {
                                          model: {
                                            value: row.batchNumber,
                                            callback: function($$v) {
                                              _vm.$set(row, "batchNumber", $$v)
                                            },
                                            expression: "row.batchNumber"
                                          }
                                        })
                                      ],
                                      1
                                    )
                                  : _c("span", [_vm._v("-")])
                              ]
                        ],
                        2
                      )
                    }
                  },
                  {
                    key: "sequenceNumber",
                    fn: function(ref) {
                      var row = ref.row
                      return _c(
                        "div",
                        {},
                        [
                          row.isAddMaterials === 0
                            ? [_vm._v(_vm._s(row.sequenceNumber || "-"))]
                            : [
                                +_vm.params.operateType === 0 ||
                                +_vm.params.operateType === 5 ||
                                +_vm.params.operateType === 4
                                  ? [
                                      +row.isSequenceNumber === 1
                                        ? _c(
                                            "div",
                                            {
                                              staticStyle: {
                                                display: "flex",
                                                "align-items": "center",
                                                "justify-content": "flex-start"
                                              }
                                            },
                                            [
                                              _c(
                                                "span",
                                                {
                                                  staticStyle: {
                                                    color: "#f00",
                                                    width: "10px"
                                                  }
                                                },
                                                [_vm._v("*")]
                                              ),
                                              _c("el-input", {
                                                model: {
                                                  value: row.sequenceNumber,
                                                  callback: function($$v) {
                                                    _vm.$set(
                                                      row,
                                                      "sequenceNumber",
                                                      $$v
                                                    )
                                                  },
                                                  expression:
                                                    "row.sequenceNumber"
                                                }
                                              })
                                            ],
                                            1
                                          )
                                        : _c("span", [_vm._v("-")])
                                    ]
                                  : _c("span", [
                                      _vm._v(_vm._s(row.sequenceNumber || "-"))
                                    ])
                              ]
                        ],
                        2
                      )
                    }
                  },
                  {
                    key: "warehouseName",
                    fn: function(ref) {
                      var row = ref.row
                      var $index = ref.$index
                      return _c(
                        "div",
                        {
                          staticStyle: {
                            display: "flex",
                            "align-items": "center",
                            "justify-content": "flex-start"
                          }
                        },
                        [
                          row.isAddMaterials === 0
                            ? [_vm._v(_vm._s(row.warehouseName))]
                            : [
                                _c(
                                  "span",
                                  {
                                    staticStyle: {
                                      color: "#f00",
                                      width: "10px"
                                    }
                                  },
                                  [_vm._v("*")]
                                ),
                                _c(
                                  "el-select",
                                  {
                                    attrs: { placeholder: "请选择" },
                                    model: {
                                      value: row.warehouseId,
                                      callback: function($$v) {
                                        _vm.$set(row, "warehouseId", $$v)
                                      },
                                      expression: "row.warehouseId"
                                    }
                                  },
                                  _vm._l(_vm.allWarehouseList, function(item) {
                                    return _c("el-option", {
                                      key: item.id,
                                      attrs: {
                                        label: item.name,
                                        value: item.id
                                      },
                                      nativeOn: {
                                        click: function($event) {
                                          return _vm.selectWarehouse(
                                            item,
                                            $index
                                          )
                                        }
                                      }
                                    })
                                  }),
                                  1
                                )
                              ]
                        ],
                        2
                      )
                    }
                  },
                  {
                    key: "materialWarehouseLocationName",
                    fn: function(ref) {
                      var row = ref.row
                      return _c(
                        "div",
                        {
                          staticStyle: {
                            display: "flex",
                            "align-items": "center",
                            "justify-content": "flex-start"
                          }
                        },
                        [
                          row.isAddMaterials === 0
                            ? [_vm._v(_vm._s(row.warehouseLocationName))]
                            : [
                                row.isAddMaterialsLocation === 1
                                  ? _c(
                                      "span",
                                      {
                                        staticStyle: {
                                          color: "#f00",
                                          width: "10px"
                                        }
                                      },
                                      [_vm._v("*")]
                                    )
                                  : _vm._e(),
                                _c("el-cascader", {
                                  key: row.keyValue,
                                  attrs: {
                                    options: row.materialWarehouseLocationList,
                                    props: { value: "id", label: "name" }
                                  },
                                  model: {
                                    value: row.warehouseLocationIdList,
                                    callback: function($$v) {
                                      _vm.$set(
                                        row,
                                        "warehouseLocationIdList",
                                        $$v
                                      )
                                    },
                                    expression: "row.warehouseLocationIdList"
                                  }
                                })
                              ]
                        ],
                        2
                      )
                    }
                  },
                  {
                    key: "warehouseLocationName",
                    fn: function(ref) {
                      var row = ref.row
                      return _c(
                        "div",
                        {
                          staticStyle: {
                            display: "flex",
                            "align-items": "center",
                            "justify-content": "flex-start"
                          }
                        },
                        [
                          row.isAddMaterials === 0
                            ? [_vm._v(_vm._s(row.warehouseLocationName))]
                            : [
                                +_vm.isLocation === 1
                                  ? _c(
                                      "span",
                                      {
                                        staticStyle: {
                                          color: "#f00",
                                          width: "10px"
                                        }
                                      },
                                      [_vm._v("*")]
                                    )
                                  : _vm._e(),
                                _c("el-cascader", {
                                  attrs: {
                                    options: _vm.warehouseLocationList,
                                    props: { value: "id", label: "name" }
                                  },
                                  model: {
                                    value: row.warehouseLocationIdList,
                                    callback: function($$v) {
                                      _vm.$set(
                                        row,
                                        "warehouseLocationIdList",
                                        $$v
                                      )
                                    },
                                    expression: "row.warehouseLocationIdList"
                                  }
                                })
                              ]
                        ],
                        2
                      )
                    }
                  },
                  {
                    key: "inWarehouseLocationName",
                    fn: function(ref) {
                      var row = ref.row
                      return _c(
                        "div",
                        {
                          staticStyle: {
                            display: "flex",
                            "align-items": "center",
                            "justify-content": "flex-start"
                          }
                        },
                        [
                          _c(
                            "span",
                            { staticStyle: { color: "#f00", width: "10px" } },
                            [_vm._v("*")]
                          ),
                          _c("el-cascader", {
                            key: row.keyValue,
                            attrs: {
                              options: _vm.fixArr(row.warehouseLocationId),
                              props: { value: "id", label: "name" }
                            },
                            model: {
                              value: row.warehouseLocationIdList,
                              callback: function($$v) {
                                _vm.$set(row, "warehouseLocationIdList", $$v)
                              },
                              expression: "row.warehouseLocationIdList"
                            }
                          })
                        ],
                        1
                      )
                    }
                  },
                  {
                    key: "action",
                    fn: function(ref) {
                      var row = ref.row
                      var $index = ref.$index
                      return _c(
                        "div",
                        {},
                        [
                          _vm.params.operateType === 0
                            ? _c(
                                "el-button",
                                {
                                  attrs: { type: "text" },
                                  on: {
                                    click: function($event) {
                                      return _vm.copyRow(row, $index)
                                    }
                                  }
                                },
                                [_vm._v("复制")]
                              )
                            : _vm._e(),
                          ([0, 1, 2, 3].includes(+_vm.params.operateType) &&
                            !row.isEdit) ||
                          ([4, 5].includes(+_vm.params.operateType) &&
                            +row.isAddMaterials === 1)
                            ? _c(
                                "el-button",
                                {
                                  attrs: { type: "text" },
                                  on: {
                                    click: function($event) {
                                      return _vm.delRow(row, $index)
                                    }
                                  }
                                },
                                [_vm._v("移除")]
                              )
                            : _vm._e(),
                          [1, 2, 3].includes(+_vm.params.operateType) &&
                          row.isEdit
                            ? _c(
                                "el-button",
                                {
                                  attrs: { type: "text" },
                                  on: {
                                    click: function($event) {
                                      return _vm.canceldelRow(row, $index)
                                    }
                                  }
                                },
                                [_vm._v("撤销移除")]
                              )
                            : _vm._e()
                        ],
                        1
                      )
                    }
                  }
                ])
              })
            ],
            1
          )
        ])
      ]),
      _c("div", { staticClass: "order-footer" }, [
        _c("div", { staticClass: "footer-le" }),
        _c(
          "div",
          { staticClass: "footer-ri" },
          [
            _c(
              "el-button",
              { attrs: { size: "small" }, on: { click: _vm.returns } },
              [_vm._v("取消")]
            ),
            _c(
              "el-button",
              {
                attrs: {
                  type: "primary",
                  loading: _vm.buttonLoading,
                  size: "small"
                },
                on: { click: _vm.switchOrSubmit }
              },
              [_vm._v("确定")]
            )
          ],
          1
        )
      ]),
      _c("select-materials", {
        ref: "selectMaterials",
        attrs: {
          visible: _vm.showMaterials,
          "is-add": true,
          title: "添加物料"
        },
        on: {
          "update:visible": function($event) {
            _vm.showMaterials = $event
          },
          submitForm: _vm.submitMaterials,
          addMaterial: _vm.addMaterial
        }
      }),
      _c("edit-materials", {
        ref: "editMaterials",
        on: { onSuccess: _vm.onSuccess }
      }),
      _c("select-materials-inventory", {
        ref: "selectMaterialInventory",
        attrs: {
          visible: _vm.showMaterialInventory,
          conditions: _vm.conditions,
          "warehouse-id": _vm.selectWarehouseId,
          "select-material-inventorys": _vm.selectMaterials,
          "operate-type": _vm.operateTypes,
          "is-container": _vm.isContainer
        },
        on: {
          "update:visible": function($event) {
            _vm.showMaterialInventory = $event
          },
          submitForm: _vm.submitMaterialInventory
        }
      })
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }